import React, {Component} from 'react';
import {Icon, IconButton, Typography} from '@material-ui/core';
import {FuseUtils, FuseAnimate} from '@fuse';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import ReactTable from "react-table";
import * as Actions from './store/actions';
import {Modal} from 'antd';
import {showMessage} from 'app/store/actions/fuse';
const { confirm } = Modal;

class UsersList extends Component {
    
    getFilteredArray = (entities, searchText) => {
        const arr = Object.keys(entities).map((id) => entities[id]);
        if ( searchText.length === 0 )
        {
            return arr;
        }
        return FuseUtils.filterArrayByString(arr, searchText);
    };

    handleDeleteEvent = (record) => {
        const {removeUser} = this.props;
        confirm({
            title: 'Do you want to delete this Item?',
            onOk() {
                removeUser(record)
            },
            onCancel() {},
        });
    }
    
    render()
    {
        const { user, users, searchText, openEditUserDialog, showMessage} = this.props;
        const data = this.getFilteredArray(users, searchText);

        if ( !data && data.length === 0 )
        {
            return (
                <div className="flex items-center justify-center h-full">
                    <Typography color="textSecondary" variant="h5">
                       No Data
                    </Typography>
                </div>
            );
        }

        return (
            <FuseAnimate animation="transition.slideUpIn" delay={300}>
                <ReactTable
                    className="-striped -highlight border-0"
                    getTrProps={(state, rowInfo, column) => {
                        return {
                            className: "cursor-pointer",
                            onClick  : (e, handleOriginal) => {
                                if ( rowInfo )
                                {
                                    openEditUserDialog(rowInfo.original);
                                }
                            }
                        }
                    }}
                    data={data}
                    columns={[
                        {
                            Header   : () => (
                                <Icon></Icon>
                            ),
                            accessor : "",
                            Cell     : row => {
                                return (<Icon>person</Icon>
                                )
                            },
                            className: "justify-center",
                            sortable : false,
                            width    : 64
                        },
                        {
                            Header    : "User Name",
                            accessor  : "username",
                            filterable: false,
                            className : "justify-center font-bold"
                        },
                        {
                            Header    : "User ID",
                            accessor  : "userid",
                            filterable: false,
                            className : "justify-center font-bold"
                        },
                        {
                            Header    : "User Role",
                            accessor  : "role",
                            filterable: false,
                            className : "justify-center font-bold"
                        },
                        {
                            Header    : "Email",
                            accessor  : "email",
                            filterable: false,
                            className : "justify-center font-bold"
                        },
                        {
                            Header: "",
                            width : 128,
                            Cell  : row => {
                                return (
                                    <div className="flex items-center">
                                        <IconButton
                                            onClick={(ev) => {
                                                ev.stopPropagation();
                                                if (user.role === 'superdesigner' && row.original.role === 'superadmin') {
                                                    showMessage({
                                                        message         : 'You are not allowed.',
                                                        autoHideDuration: 2000,
                                                        anchorOrigin    : {
                                                            vertical  : 'top',
                                                            horizontal: 'right'
                                                        },variant : 'error' });
                                                    return;
                                                }
                                                this.handleDeleteEvent(row.original.id);
                                            }}
                                        >
                                            <Icon>delete</Icon>
                                        </IconButton>
                                    </div>
                                );
                            }
                        }
                    ]}
                    defaultPageSize={10}
                    noDataText="No Data"
                />
            </FuseAnimate>
        );
    }
}


function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        getUsers             : Actions.getUsers,
        openEditUserDialog   : Actions.openEditUserDialog,
        removeUser           : Actions.removeUser,
        showMessage          : showMessage
    }, dispatch);
}

function mapStateToProps({usersApp, auth})
{
    return {
        user            : auth.user,
        users           : usersApp.users.entities,
        searchText      : usersApp.users.searchText,
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UsersList));
