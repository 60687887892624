import UsersApp from './UsersApp';
import {authRoles} from 'app/auth';

export const UsersAppConfig = {
    settings: {
        layout: {
        }
    },
    auth: authRoles.superDesigner,
    routes  : [
        {
            path     : '/user',
            component: UsersApp
        }
    ]
};
