import axios from 'axios';
import {
    showMessage
} from 'app/store/actions/fuse';
import {
    API_SERVER_URL,
    jsonArray,
    shapeSVGValue,
    uploadFiles,
    putFiles,
    deleteFiles,
    getDoorStyleTreeData,
    PH_ARRAY,
} from 'app/main/config';
import { uploadLoading }from 'app/store/actions/fuse';
import {getCabinet} from './cabinet.action';

export const GET_CONST_DATA = '[CABINET_DOOR_STYLE] GET CONST DATA';
export const GET_CABINET_DOOR_STYLE = '[CABINET_DOOR_STYLE] GET CABINET DOOR STYLE';
export const GET_MANUFACTURER = '[CABINET_DOOR_STYLE] GET MANUFACTURER';
export const GET_COLLECTION = '[CABINET_DOOR_STYLE] GET COLLECTION';
export const GET_DOOR_STYLE = '[CABINET_DOOR_STYLE] GET DOOR STYLE';
export const GET_DOOR_COLOR = '[CABINET_DOOR_COLOR] GET DOOR COLOR';
export const ADD_CABINET_DOOR_STYLE = '[CABINET_DOOR_STYLE] ADD CABINET DOOR STYLE';
export const DELETE_CABINET_DOOR_STYLE = '[CAIBNET_DOOR_STYLE] DELETE CABINET DOOR STYLE';
export const UPDATE_CABINET_DOOR_STYLE = '[CABINET_DOOR_STYLE] UPDATE CABINET DOOR STYLE';
export const SET_CABINET_DOOR_STYLE_IDS = '[CABINET_DOOR_STYLE] SET CABINET DOOR STYLE IDS';

export function setCabinetDoorStyleIDs(cds_ids) {
    return (dispatch) => {
        dispatch({
            type: SET_CABINET_DOOR_STYLE_IDS,
            cds_ids
        })
    }
}

export function getConstData() {
    return (dispatch => {
        dispatch(getCabinetDoorStyle());
        dispatch(getManufacturer());
        dispatch(getCollection());
        dispatch(getDoorStyle());
        dispatch(getDoorColor());
    })
}

export function getCabinetDoorStyle() {
    return (dispatch => {
        axios.post(`${API_SERVER_URL}/api/cabinetDoorStyle/read/cabinet_door_style`)
            .then(response => {
                const {
                    cabinetDoorStyle
                } = response.data;
                dispatch({
                    type: GET_CABINET_DOOR_STYLE,
                    cabinetDoorStyle,
                })
            }).catch(err => {
                dispatch(showMessage({
                    message: 'Failed to load Cabinet Door Style data',
                    autoHideDuration: 2000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
                throw err;
            });
    })
}

export function getManufacturer() {
    return (dispatch => {
        axios.post(`${API_SERVER_URL}/api/manufacturer/read/manufacturer`)
            .then(response => {
                const {
                    manufacturerData,
                    success
                } = response.data;
                if (success === true) {
                    dispatch({
                        type: GET_MANUFACTURER,
                        manufacturerData: manufacturerData,
                    });
                }
            }).catch(err => {
                dispatch(showMessage({
                    message: 'Failed to load MANUFACTURER data',
                    autoHideDuration: 2000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
                throw err;
            });
    });

}

export function getCollection() {
    return (dispatch => {
        axios.post(`${API_SERVER_URL}/api/collection/read/collection`)
            .then(response => {
                const {
                    collectionData,
                    success
                } = response.data;
                if (success === true) {
                    dispatch({
                        type: GET_COLLECTION,
                        collectionData: collectionData,
                    });
                }
            })
    });
}

export function getDoorStyle() {
    return (dispatch => {
        axios.post(`${API_SERVER_URL}/api/doorStyle/read/door_style`)
            .then(response => {
                const {
                    doorStyle,
                    success
                } = response.data;
                if (success === true) {
                    axios.post(`${API_SERVER_URL}/api/doorCategory/read/door_category`)
                    .then(response => {
                        const {doorCategory, success} = response.data;
                        if(success === true) {
                            const treeData = getDoorStyleTreeData(doorCategory, doorStyle, 0, "");
                            const result = [];
                            var loop = data => data.map((item) => {
                                if( item.items === undefined) {
                                    result.push(item);
                                    return item;
                                }
                                else {
                                    return loop(item.items);
                                }
                            });
                            loop(treeData);
                            doorStyle.map(item => {
                                item.path = result.find(el => {return el.id === item.id}).path;
                                return item;
                            })
                            dispatch({
                                type: GET_DOOR_STYLE,
                                doorStyle: doorStyle,
                            });
                        }
                    });
                }
            }).catch(err => {
                dispatch(showMessage({
                    message: 'Failed to load DOOR STYLE data',
                    autoHideDuration: 2000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
                throw err;
            });
    })
}

export function getDoorColor() {
    return (dispatch => {
        axios.post(`${API_SERVER_URL}/api/doorColor/read/door_color`)
        .then(response => {
            const {doorColor, success} = response.data;
            if(success === true) {     
                dispatch({
                    type:GET_DOOR_COLOR,
                    doorColor,
                })
            }
        })
        .catch(err => {
            dispatch(showMessage({
                message         : 'Failed to load DOOR COLOR data',
                autoHideDuration: 2000,
                anchorOrigin    : {
                    vertical  : 'top',
                    horizontal: 'right'
                },variant : 'error'
            }));
            console.log("err", err);
        })    
    })
}

export function addCabinetDoorStyle(id, data, cabinet_shape_svg) {
    var base_gltf = {
        'gltf' : jsonArray(data.base_gltf)
    };

    var door_data = {};
    var drawer_data = {};
    PH_ARRAY.forEach((el, key) => {
        door_data['door_' + el]                 = jsonArray(data['door_' + el]);
        drawer_data['fixed_drawer_door_' + el]  = jsonArray(data['fixed_drawer_door_' + el]);
        drawer_data['drawer_' + el]             = jsonArray(data['drawer_' + el]);
        drawer_data['drawer_door_' + el]        = jsonArray(data['drawer_door_' + el]);
    });
    
    var data_string = JSON.stringify(door_data);
    var drawer_string = JSON.stringify(drawer_data);

    var door_color_ids = null;
    if (data.door_color_ids !== undefined && data.door_color_ids.length !== 0) {
        door_color_ids = "";
        data.door_color_ids.forEach((el, key) => {
            door_color_ids += el.toString();
            if( key !== data.door_color_ids.length - 1) {
                door_color_ids += ',';
            }
        })
    }

    var getArray = (data) => {
        var items = '';
        data.forEach(( fElement, index) => {
            if(index === 0)
                items += fElement;
            else
                items += ',' + fElement;
        });
        return items;
    }

    var newData = {
        name: data.cabinet_door_style_name,
        manufacturer_id: data.manufacturer_id,
        collection_id: data.collection_id,
        door: data_string,
        drawer: drawer_string,
        basic_cds: data.basic_cds,
        base_gltf: JSON.stringify(base_gltf),
        sku: data.sku,
        door_color_ids: door_color_ids,
        is_euro_cabinet: data.is_euro_cabinet ? 1 : 0,
        cabinet_id: null,
        euro_shape_svg: data.is_euro_cabinet && shapeSVGValue (data.euro_shape_svg.length > 0 ? data.euro_shape_svg : cabinet_shape_svg),
        euro_width: data.is_euro_cabinet && data.width.number,
        euro_height: data.is_euro_cabinet && data.height.number,
        euro_length: data.is_euro_cabinet && data.length.number,
        width_unit: data.is_euro_cabinet && data.width.currency,
        height_unit: data.is_euro_cabinet && data.height.currency,
        length_unit: data.is_euro_cabinet && data.length.currency,
        leftBlindLength: data.is_euro_cabinet && data.leftBlindLength.number,
        rightBlindLength: data.is_euro_cabinet && data.rightBlindLength.number,
        leftBlindLength_unit: data.is_euro_cabinet && data.leftBlindLength.currency,
        rightBlindLength_unit: data.is_euro_cabinet && data.rightBlindLength.currency,
        euro_comments: data.is_euro_cabinet && data.comments
    }

    const formData = new FormData();
    PH_ARRAY.forEach((el, key) => {
        data['door_' + el].forEach(fElement => {
            formData.append('door_' + el, fElement.originFileObj);
        })
        data['fixed_drawer_door_' + el].forEach(fElement => {
            formData.append('fixed_drawer_door_' + el, fElement.originFileObj);
        })
        data['drawer_' + el].forEach(fElement => {
            formData.append('drawer_' + el, fElement.originFileObj);
        })
        data['drawer_door_' + el].forEach(fElement => {
            formData.append('drawer_door_' + el, fElement.originFileObj);
        })
    });
    
    data.base_gltf && data.base_gltf.forEach(fElement => {
        formData.append("base_gltf", fElement.originFileObj);
    })
    if (data.euro_shape_svg.length > 0) {
        if (data.euro_shape_svg[0].originFileObj !== undefined) {
            formData.append ("euro_shape_svg", data.euro_shape_svg[0].originFileObj);
        }
    }

    return ((dispatch, getState) => {

        axios.post(`${API_SERVER_URL}/api/cabinetDoorStyle/insert/cabinet_door_style`, {
                ...newData,
                cbt_id: id,
            })
            .then(res => {
                dispatch(uploadLoading(true));
                if (process.env.REACT_APP_MODE === "production") {
                    let uploadArray = [];
                    if (data.base_gltf) {
                        uploadArray.push('base_gltf');
                    }
                    if (data.euro_shape_svg) {
                        uploadArray.push('euro_shape_svg');
                    }
                    PH_ARRAY.forEach((el, key) => {
                        uploadArray.push('door_' + el);
                        uploadArray.push('fixed_drawer_door_' + el);
                        uploadArray.push('drawer_' + el);
                        uploadArray.push('drawer_door_' + el);
                    });

                    let resultArray = [];

                    let uploadFilesArray = function(uploads, results) {
                        if (uploads.length === 0) {
                            var door_data = [];
                            var drawer_data = [];
                            PH_ARRAY.forEach((el, key) => {
                                door_data.push({
                                    jsonFieldName: 'door_' + el,
                                    data: results['door_' + el],
                                });
                                drawer_data.push({
                                    jsonFieldName: 'fixed_drawer_door_' + el,
                                    data: results['fixed_drawer_door_' + el],
                                });
                                drawer_data.push({
                                    jsonFieldName: 'drawer_' + el,
                                    data: results['drawer_' + el],
                                });
                                drawer_data.push({
                                    jsonFieldName: 'drawer_door_' + el,
                                    data: results['drawer_door_' + el],
                                });
                            });                        

                            const uploadData = [
                                {
                                    fieldName: 'door',
                                    type: 'multi',
                                    data: door_data
                                },
                                {
                                    fieldName: 'drawer',
                                    type: 'multi',
                                    data: drawer_data
                                },
                                {
                                    fieldName: 'base_gltf',
                                    type: 'multi',
                                    data: [ 
                                        {
                                            jsonFieldName: 'gltf',
                                            data: results['base_gltf'],
                                        }
                                    ]
                                },
                                {
                                    fieldName: 'euro_shape_svg',
                                    type: 'single',
                                    data: results['euro_shape_svg'],
                                },
                            ]

                            axios.post(`${API_SERVER_URL}/api/base/updateUploadData`, {
                                id: res.data.data.id,
                                tableName: "cabinet_door_style",
                                data: uploadData,
                            })
                            .then(response => {
                                const {
                                    success
                                } = response.data;
                                dispatch(uploadLoading(false));
                                if (success === true) {
                                    const {cds_ids} = getState().cabinet.cabinetDoorStyle;
                                    cds_ids.push(res.data.data.id);
                                    dispatch(showMessage({
                                        message: 'Successfully Inserted',
                                        autoHideDuration: 2000,
                                        anchorOrigin: {
                                            vertical: 'top',
                                            horizontal: 'right'
                                        },
                                        variant: 'success'
                                    }));
                                    Promise.all([
                                        dispatch({
                                            type: ADD_CABINET_DOOR_STYLE,
                                            cds_ids,
                                        })
                                    ]).then(() => { 
                                        dispatch(getCabinetDoorStyle()); 
                                        // console.log("addCDS in cabinet number ",id, " in cds action of production mode");
                                        if(id !== null && id !== undefined) {
                                            // console.log("getCabinet again");
                                            dispatch(getCabinet());
                                        }
                                    })
                                } else {
                                    console.log(success);
                                }
                            })
                            .catch(err => {
                                console.log("failed to save uploaded data in insert action", err);
                                throw err;
                            })
                        } else {
                            let key = uploads.pop();
                            uploadFiles(data[key].filter(file => file.originFileObj !== undefined), `uploads/CabinetDoorStyle/${res.data.data.guid + '_' + res.data.data.id}/${key}/`).then(url => {
                                results[key] = url;
                                uploadFilesArray(uploads, results);
                            });
                        }
                    };

                    uploadFilesArray(uploadArray, resultArray);
                } else {
                    formData.append("guid", res.data.data.guid);
                    formData.append("id", res.data.data.id);
                    axios.post(`${API_SERVER_URL}/api/cabinetDoorStyle/upload`, formData)
                        .then(response => {
                            const {
                                success
                            } = response.data;
                            if (success === true) {
                                const {cds_ids} = getState().cabinet.cabinetDoorStyle;
                                cds_ids.push(res.data.data.id);

                                dispatch(uploadLoading(false));
                                dispatch(showMessage({
                                    message: 'Successfully Inserted',
                                    autoHideDuration: 2000,
                                    anchorOrigin: {
                                        vertical: 'top',
                                        horizontal: 'right'
                                    },
                                    variant: 'success'
                                }));
                                Promise.all([
                                    dispatch({
                                        type: ADD_CABINET_DOOR_STYLE,
                                        cds_ids,
                                    })
                                ]).then(() => {
                                    dispatch(getCabinetDoorStyle());
                                    // console.log("addCDS in cabinet number ",id, " in cds action of development mode");
                                    if(id !== null && id !== undefined) {
                                        // console.log("getCabinet again");
                                        dispatch(getCabinet());
                                    }
                                })
                            }
                        }).catch(err => {
                            dispatch(showMessage({
                                message: 'Failed to add CABINET DOOR STYLE data',
                                autoHideDuration: 2000,
                                anchorOrigin: {
                                    vertical: 'top',
                                    horizontal: 'right'
                                },
                                variant: 'error'
                            }));
                            throw err;
                        });
                }
            })
            .catch(err => {
                console.log("failed to insert cabinet_door_style data", err);
                throw err;
            });
    })
}

export function deleteCabinetDoorStyle(id, record) {
    return ((dispatch, getState) => {
            axios.post(`${API_SERVER_URL}/api/cabinetDoorStyle/remove/cabinet_door_style`, {
                cbt_id: id,
                cds_id: record.id,
            })
            .then(response => {
                const {
                    success
                } = response.data;
                if (success === true) {
                    var p1 = new Promise((resolve, reject) => {
                        dispatch(uploadLoading(true));
                        if(process.env.REACT_APP_MODE === "production" ) {
                            const data = [];
                            const door_data = JSON.parse(record.cabinet_door);
                            const drawer_data = JSON.parse(record.cabinet_drawer);
                            PH_ARRAY.forEach((el, key) => {
                                door_data['door_' + el].forEach(i => data.push(i));
                                drawer_data['fixed_drawer_door_' + el].forEach(i => data.push(i));
                                drawer_data['drawer_' + el].forEach(i => data.push(i));
                                drawer_data['drawer_door_' + el].forEach(i => data.push(i));
                            })
                            JSON.parse(record.base_gltf)['gltf'].forEach(i => data.push(i));

                            deleteFiles(data).then(res => {
                                resolve("success");
                            })
                            .catch(err => {
                                console.log(err);
                                reject(err);
                            })
                        } else {
                            resolve("success");
                        }
                    })
                    p1.then(p1Value => {
                        const {cds_ids} = getState().cabinet.cabinetDoorStyle;
                        const fIndex = cds_ids.findIndex(fElement => {return record.id === fElement});
                        cds_ids.splice(fIndex, 1);
                        dispatch(uploadLoading(false));
                        dispatch(showMessage({
                            message: 'Successfully Deleted',
                            autoHideDuration: 2000,
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right'
                            },
                            variant: 'success'
                        }));
                        Promise.all([
                            dispatch({
                                type: DELETE_CABINET_DOOR_STYLE,
                                cds_ids,
                            })
                        ]).then(() => {
                            dispatch(getCabinetDoorStyle()); 
                            // console.log("deleteCDS in cabinet number ",id, " in cds action");
                            if(id !== null && id !== undefined) {
                                // console.log("getCabinet again");
                                dispatch(getCabinet());
                            }
                        })
                    }).catch (err => {
                        console.log(err);
                        dispatch(uploadLoading(false));
                        dispatch(showMessage({
                            message: 'AWS S3 DELETE CABINET_DOOR_STYLE ERROR',
                            autoHideDuration: 4000,
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'right'
                            },
                            variant: 'error'
                        }));
                        return;
                    })
                }
                else {
                    dispatch(showMessage({
                        message: 'Some cabinets are using this style',
                        autoHideDuration: 4000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'warning'
                    }));
                }
            }).catch(err => {
                dispatch(uploadLoading(false));
                dispatch(showMessage({
                    message: 'Failed to delete CABINET DOOR STYLE data',
                    autoHideDuration: 2000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
                throw err;
            });
    })
}

export function updateCabinetDoorStyle(data, id, guid, cabinet_shape_svg) {
    var base_gltf = {
        'gltf' : jsonArray(data.base_gltf)
    };

    var door_data = {};
    var drawer_data = {};
    PH_ARRAY.forEach((el, key) => {
        door_data['door_' + el]                 = jsonArray(data['door_' + el]);
        drawer_data['fixed_drawer_door_' + el]  = jsonArray(data['fixed_drawer_door_' + el]);
        drawer_data['drawer_' + el]             = jsonArray(data['drawer_' + el]);
        drawer_data['drawer_door_' + el]        = jsonArray(data['drawer_door_' + el]);
    });
    
    var data_string = JSON.stringify(door_data);
    var drawer_string = JSON.stringify(drawer_data);
    
    var door_color_ids = null;
    if (data.door_color_ids !== undefined && data.door_color_ids.length !== 0) {
        door_color_ids = "";
        data.door_color_ids.forEach((el, key) => {
            door_color_ids += el.toString();
            if( key !== data.door_color_ids.length - 1) {
                door_color_ids += ',';
            }
        })
    }

    var getArray = (data) => {
        var items = '';
        data.forEach(( fElement, index) => {
            if(index === 0)
                items += fElement;
            else
                items += ',' + fElement;
        });
        return items;
    }

    var updateData = {
        id: id,
        name: data.cabinet_door_style_name,
        manufacturer_id: data.manufacturer_id,
        collection_id: data.collection_id,
        door: data_string,
        drawer: drawer_string,
        basic_cds: data.basic_cds,
        base_gltf: JSON.stringify(base_gltf),
        sku: data.sku,
        door_color_ids: door_color_ids,
        is_euro_cabinet: data.is_euro_cabinet ? 1 : 0,
        cabinet_id: null,
        euro_shape_svg: data.is_euro_cabinet && shapeSVGValue (data.euro_shape_svg.length > 0 ? data.euro_shape_svg : cabinet_shape_svg),
        euro_width: data.is_euro_cabinet && data.width.number,
        euro_height: data.is_euro_cabinet && data.height.number,
        euro_length: data.is_euro_cabinet && data.length.number,
        width_unit: data.is_euro_cabinet && data.width.currency,
        height_unit: data.is_euro_cabinet && data.height.currency,
        length_unit: data.is_euro_cabinet && data.length.currency,
        leftBlindLength: data.is_euro_cabinet && data.leftBlindLength.number,
        rightBlindLength: data.is_euro_cabinet && data.rightBlindLength.number,
        leftBlindLength_unit: data.is_euro_cabinet && data.leftBlindLength.currency,
        rightBlindLength_unit: data.is_euro_cabinet && data.rightBlindLength.currency,
        euro_comments: data.is_euro_cabinet && data.comments
    }

    const formData = new FormData();
    PH_ARRAY.forEach((el, key) => {
        data['door_' + el].forEach(fElement => {
            formData.append('door_' + el, fElement.originFileObj);
        })
        data['fixed_drawer_door_' + el].forEach(fElement => {
            formData.append('fixed_drawer_door_' + el, fElement.originFileObj);
        })
        data['drawer_' + el].forEach(fElement => {
            formData.append('drawer_' + el, fElement.originFileObj);
        })
        data['drawer_door_' + el].forEach(fElement => {
            formData.append('drawer_door_' + el, fElement.originFileObj);
        })
    });
    
    data.base_gltf.forEach(fElement => {
        formData.append("base_gltf", fElement.originFileObj);
    })

    if (data.euro_shape_svg.length > 0) {
        if (data.euro_shape_svg[0].originFileObj !== undefined) {
            formData.append("euro_shape_svg", data.euro_shape_svg[0].originFileObj);
        }
    }
    return ((dispatch) => {
        axios.post(`${API_SERVER_URL}/api/cabinetDoorStyle/update/cabinet_door_style`, {
                ...updateData
            })
            .then(res => {
                dispatch(uploadLoading(true));
                if (process.env.REACT_APP_MODE === "production") {
                    
                    let uploadArray = ['base_gltf', 'euro_shape_svg'];
                    PH_ARRAY.forEach((el, key) => {
                        uploadArray.push('door_' + el);
                        uploadArray.push('fixed_drawer_door_' + el);
                        uploadArray.push('drawer_' + el);
                        uploadArray.push('drawer_door_' + el);
                    });

                    let resultArray = [];

                    let putFilesArray = function(uploads, results) {
                        if (uploads.length === 0) {
                            var door_data = [];
                            var drawer_data = [];
                            PH_ARRAY.forEach((el, key) => {
                                door_data.push({
                                    jsonFieldName: 'door_' + el,
                                    data: results['door_' + el],
                                });
                                drawer_data.push({
                                    jsonFieldName: 'fixed_drawer_door_' + el,
                                    data: results['fixed_drawer_door_' + el],
                                });
                                drawer_data.push({
                                    jsonFieldName: 'drawer_' + el,
                                    data: results['drawer_' + el],
                                });
                                drawer_data.push({
                                    jsonFieldName: 'drawer_door_' + el,
                                    data: results['drawer_door_' + el],
                                });
                            });                        

                            const uploadData = [
                                {
                                    fieldName: 'door',
                                    type: 'multi',
                                    data: door_data
                                },
                                {
                                    fieldName: 'drawer',
                                    type: 'multi',
                                    data: drawer_data
                                },
                                {
                                    fieldName: 'base_gltf',
                                    type: 'multi',
                                    data: [ 
                                        {
                                            jsonFieldName: 'gltf',
                                            data: results['base_gltf'],
                                        }
                                    ]
                                },
                                {
                                    fieldName: 'euro_shape_svg',
                                    type: 'single',
                                    data: results['euro_shape_svg'],
                                },
                            ]

                            axios.post(`${API_SERVER_URL}/api/base/updateUploadData`, {
                                id: id,
                                tableName: "cabinet_door_style",
                                data: uploadData,
                            })
                            .then(response => {
                                const {
                                    success
                                } = response.data;
                                dispatch(uploadLoading(false));
                                if (success === true) {
                                    dispatch(showMessage({
                                        message: 'Successfully Inserted',
                                        autoHideDuration: 2000,
                                        anchorOrigin: {
                                            vertical: 'top',
                                            horizontal: 'right'
                                        },
                                        variant: 'success'
                                    }));
                                    Promise.all([
                                        dispatch({
                                            type: UPDATE_CABINET_DOOR_STYLE
                                        })
                                    ]).then(() => dispatch(getCabinetDoorStyle()))
                                } else {
                                    console.log(success);
                                }
                            })
                            .catch(err => {
                                console.log("failed to save uploaded data with update action", err);
                                throw err;
                            })
                        } else {
                            let key = uploads.pop();
                            putFiles(data[key].filter(file => file.originFileObj !== undefined), `uploads/CabinetDoorStyle/${guid + '_' + id}/${key}/`).then(url => {
                                results[key] = url;
                                putFilesArray(uploads, results);
                            });
                        }
                    }
                    
                    putFilesArray(uploadArray, resultArray);
                } else {
                    formData.append("guid", guid);
                    formData.append("id", id);
                    axios.post(`${API_SERVER_URL}/api/cabinetDoorStyle/upload`, formData)
                        .then(response => {
                            const {
                                success
                            } = response.data;
                            dispatch(uploadLoading(false));
                            if (success === true) {
                                dispatch(showMessage({
                                    message: 'Successfully Updated',
                                    autoHideDuration: 2000,
                                    anchorOrigin: {
                                        vertical: 'top',
                                        horizontal: 'right'
                                    },
                                    variant: 'success'
                                }));
                                dispatch(getCabinetDoorStyle());
                                // Promise.all([
                                //     dispatch({
                                //         type: UPDATE_CABINET_DOOR_STYLE
                                //     })
                                // ]).then(() => ))
                            }
                        })
                }

            })
            .catch(err => {
                dispatch(showMessage({
                    message: 'Failed to update CABINET DOOR STYLE data',
                    autoHideDuration: 2000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    variant: 'error'
                }));
                throw err;
            });
    })
}