import * as Actions from '../actions';

const initialState = {
    cabinet: [],
    cabinetCategory: [],
    modalData: undefined
};

const cabinetReducer = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.GET_CABINET:
        {
            return {
                ...state,
                ...action,
            }   
        }
        case Actions.GET_CABINET_CATEGORY:
        {
            return {
                ...state,
                ...action
            }
        }
        case Actions.SET_MODAL_DATA:
        {
            return {
                ...state,
                ...action
            }
        }
        default:
        {
            return state;
        }
    }
};

export default cabinetReducer;
