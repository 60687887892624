import React, {Component} from 'react';
import * as Actions from './store/actions';
import reducer from './store/reducers';
import {withRouter} from 'react-router-dom';
import withReducer from 'app/store/withReducer';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {FusePageCarded, FuseAnimate} from '@fuse';
import {IconButton, Button, Drawer, Divider} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import AddIcon from '@material-ui/icons/Add';
import { withStyles } from '@material-ui/core/styles';
import {Table, Typography, Input, Upload, Form, Modal, Spin, Row, Col, Icon, message, Tooltip, Select} from 'antd';
import {beautifyJSON} from 'app/main/config';

const { Title } = Typography;
const { confirm } = Modal;
const FormItem = Form.Item;
const { Dragger } = Upload;
const { Option } = Select;
const formItemLayout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 12 },
};
const defaultPagination = {
    pageSizeOptions: ['10', '20', '50', '70'],
    showSizeChanger: true,
    size: 'small',
    position: 'top',
}
const styles = theme => ({
    button: {
        marginTop: "-12px",
    },
    input: {
        display: 'none',
    },
    list: {
        width: 500,
    },
});

class UnitInput extends React.Component {
    static getDerivedStateFromProps(nextProps) {
      // Should be a controlled component.
      if ('value' in nextProps) {
        return {
          ...(nextProps.value || {}),
        };
      }
      return null;
    }
  
    constructor(props) {
      super(props);
  
      const value = props.value || {};
      this.state = {
        number: value.number.toString() || '0',
        currency: value.currency || 'inch',
      };
    }
  
    handleNumberChange = e => {
      var number = (e.target.value);

      if (!('value' in this.props)) {
        this.setState({ number });
      }
      this.triggerChange({ number });
    };
  
    handleCurrencyChange = currency => {
      if (!('value' in this.props)) {
        this.setState({ currency });
      }
      this.triggerChange({ currency });
    };
  
    triggerChange = changedValue => {
      // Should provide an event to pass value to Form.
      const { onChange } = this.props;
      if (onChange) {
        onChange(Object.assign({}, this.state, changedValue));
      }
    };
  
    render() {
      const { size } = this.props;
      const { state } = this;
      return (
        <span>
          <Input
            type="text"
            size={size}
            value={state.number}
            onChange={this.handleNumberChange}
            style={{ width: '65%', marginRight: '3%' }}
          />
          <Select
            value={state.currency}
            size={size}
            style={{ width: '32%' }}
            onChange={this.handleCurrencyChange}
          >
            <Option value="inch">inch</Option>
            <Option value="cm">cm</Option>
          </Select>
        </span>
      );
    }
}

class Lighting extends Component {
   
    constructor(props) {
        super(props);
        const value = props.value || {};
        this.state = {
            name      : '',
            gltf      : [],
            thumbnail : [],
            shape_svg : [],
            visible   : false,
            type      : "new",
            id        : null,
            
            width: value.width || 0,
            height: value.height || 0,
            width_unit: 'inch',
            height_unit: 'inch',
            length: value.length || 0,
            length_unit: 'inch',
        };
        props.getLighting();
       
    }
    checkNumber = (rule, value, callback) => {
        if (value.number > 0 || value === '.') {
            callback();
            return;
        }
        callback('Number must greater than zero!');
    };

    multiUploadFileArray = (dataArray, record) => {
        const results = dataArray.map((obj,index) => ({
            uid     : index,
            name    : obj.split('/').pop(),
            status  : 'done',
            url     : obj,
        }));

        return results;
    }
    toggleDrawer = (type, record, open) => () => {
        if(type === "new") {
            this.setState({
                id        : null,
                name      : '',
                gltf      : [],
                thumbnail : [],
                shape_svg : [],
                type      : type,
                width     : 0,
                height    : 0,
                length    : 0,
            })
        }
        else if(type === "edit") {
            const gltfArray = JSON.parse(record.gltf);
            const gltfFileList = this.multiUploadFileArray(gltfArray.gltf, record);
            this.setState({
                name: record.name,
                type            : type,
                id              : record.id,
                guid            : record.guid,
                thumbnail: [{
                    uid     : 1,
                    name    : record.thumbnail.split('/').pop(),
                    status  : 'done',
                    url     : record.thumbnail,
                }],
                shape_svg: record.shape_svg ? [{
                    uid     : 1,
                    name    : record.shape_svg.split('/').pop(),
                    status  : 'done',
                    url     : record.shape_svg,
                }] : [],
                gltf: gltfFileList,
                width        : {number:record.width, currency:record.width_unit},
                height       : {number:record.height, currency:record.height_unit},
                length       : {number:record.length, currency:record.length_unit},
            })
        }
        this.setState({
          visible: open,
        });
    };

    // canBeSubmitted()
    // {
    //     const {name, thumbnail, gltf, type} = this.state;
    //     return type==="new" ? (name.length > 0 && thumbnail.length > 0 && gltf.length > 0) : (name.length > 0);
    // }

    handleSubmit = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
              const {type, id} = this.state;
              const sizeinfo = {
                width: {
                    ...values.width,
                    number: parseFloat(values.width.number),
                },
                height: {
                    ...values.height,
                    number: parseFloat(values.height.number),
                },
                length: {
                    ...values.length,
                    number: parseFloat(values.length.number),
                }
              }
              values = { ...values, ...sizeinfo };
              this.setState({visible: false, ...values});
                if(type === "new") {
                    this.props.addLighting({...values});
                }
                if(type === "edit" && id !== null) {
                    this.props.updateLighting({...values}, id, this.state.guid);
                }
            }
          });
    }

    handleDeleteEvent = (record) => {
        const {deleteLighting} = this.props;
        confirm({
            title: 'Do you want to delete this Item?',
            onOk() {
                deleteLighting(record)
            },
            onCancel() {},
        });
    }

    normFile = e => {
        if (Array.isArray(e)) {
          return e;
        }
        return e && e.fileList;
    }

    moveAction = (type, record) => () => {
        const {lighting} = this.props;
        const index = lighting.findIndex( el => el.id === record.id);
        let swapIndex = -1; 
        if (type === "up" && index !== 0 ) {
            swapIndex = index - 1;
        }
        else if (type === "down" && index !== lighting.length - 1) {
            swapIndex = index + 1;
        }
        if(swapIndex !== -1) {
            this.props.setSortOrder({id: lighting[index].id, sort_order: lighting[swapIndex].sort_order});
            this.props.setSortOrder({id: lighting[swapIndex].id, sort_order: lighting[index].sort_order});
        }
    }
      
    render()
    {
        const { classes, lighting, isLoading} = this.props;
        const { thumbnail, shape_svg, name, gltf, visible, type, length, width, height, width_unit, height_unit, length_unit} = this.state;
        const { getFieldDecorator, setFieldsValue } = this.props.form;
        const props = {
            name: 'file',
            multiple: true,
        };
        const uploadButton = (
            <div>
              <AddIcon />
              <div className="ant-upload-text">
                {type==="new" ? "Upload" : "Change & Upload"}
            </div>
            </div>
        );
        const columns = [
            {
                key: 'name',
                title: 'Name',
                dataIndex: 'name',
                width: 200,
            },
            {
                key: 'gltf_bin',
                title: 'Gltf & Bin file',
                dataIndex: 'gltf',
                render: (text, record) => {
                    const data = beautifyJSON(text);
                    const result = [];
                    data.forEach((element, key) => {
                        result.push(<span key={key}>{element}<br/></span>);
                    })
                    return (
                    <Tooltip placement="topLeft" title={result}>
                        <span>{text.slice(0,60) + '...'}</span>
                    </Tooltip>
                    )
                },
                width: 400,
            },
            {
                key: 'thumbnail',
                title: 'Thumbnail',
                dataIndex: 'thumbnail',
                colSpan: 2,
                render: (text, record) => (
                    <Tooltip placement="topLeft" title={text}>
                        <span>{text}</span>
                    </Tooltip>
                )
            },
            {
                key: 'preview',
                dataIndex: 'thumbnail',
                colSpan: 0,
                width: 150,
                render: (text, record) => (
                    <Row className="list__thumbnail">
                        <img src={text} alt="" />
                    </Row>
                ),
            },
            {
                key: 'shape_svg',
                title: 'Shape(SVG)',
                dataIndex: 'shape_svg',
                colSpan: 2,
                width: 300,
                render: (text, record) => (
                    <Tooltip placement="topLeft" title={text}>
                        <span>{text ? text.slice(0,40) + '...' : '-'}</span>
                    </Tooltip>
                )
            },
            {
                key: 'preview_shape',
                dataIndex: 'shape_svg',
                colSpan: 0,
                width: 150,
                render: (text, record) => (
                    <Row className="list__thumbnail list__svg">
                        {text && <img src={text} alt="" />}
                    </Row>
                ),
            },
            {
                key: 'Width',
                title: 'Width',
                dataIndex: 'width',
                width: 100,
            },
            {
                key: 'length', 
                title: 'Depth',
                dataIndex: 'length',
                width: 100,
            },
            {
                key: 'height',
                title: 'Height',
                dataIndex: 'height',
                width: 100,
            },
            {
                key: 'action',
                title: 'Action',
                dataIndex: 'id',
                width: 250,
                fixed: 'right',
                render: (text, record) => (
                    <Row>
                        <IconButton className={classes.button} aria-label="ArrowUp" onClick={this.moveAction("up", record)}>
                            <ArrowUpwardIcon />
                        </IconButton>
                        <IconButton className={classes.button} aria-label="ArrowDown" onClick={this.moveAction("down", record)}>
                            <ArrowDownwardIcon />
                        </IconButton>
                        <IconButton className={classes.button} aria-label="Delete" onClick={this.toggleDrawer("edit", record, true)}>
                            <EditIcon />
                        </IconButton>
                        <IconButton className={classes.button} aria-label="Edit" onClick={e => {e.preventDefault(); this.handleDeleteEvent(record);}}>
                            <DeleteIcon />
                        </IconButton>
                    </Row>
                ),
            },
        ];

        return (
            <Spin tip="Loading..." spinning={isLoading}>
                <FusePageCarded
                    classes={{
                        toolbar: "p-0",
                        header : "min-h-72 h-72 sm:h-136 sm:min-h-136"
                    }}
                    header={
                        <div className="flex flex-1 w-full items-center justify-between">
                            <div className="flex flex-col items-start max-w-full">
                                <div className="flex items-center max-w-full">
                                    <div className="flex flex-col min-w-0">
                                        <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                                            <Title level={4} className="text-16 sm:text-20 truncate">
                                            </Title>
                                        </FuseAnimate>
                                    </div>
                                </div>
                            </div>
                            <FuseAnimate animation="transition.slideRightIn" delay={300}>
                                <Button
                                    className="whitespace-no-wrap"
                                    variant="contained"
                                    onClick={this.toggleDrawer("new", null, true)}
                                >
                                    Add
                                </Button>
                            </FuseAnimate>
                        </div>
    
                    }
                    contentToolbar={
                        <Title level={4} style={{marginLeft: "25px"}} className="text-16 sm:text-20 truncate" classes={{root: "w-full h-64"}}>
                            {"Lighting"}
                        </Title>
                    }
                    content={
                        <div className="p-24">
                            <Row>
                                <Drawer
                                    anchor="right"
                                    open={visible}
                                    variant="temporary"
                                    onClose={this.toggleDrawer(null, null, false)}
                                    classes={{paper: classes.list}}
                                    onRendered={() => {
                                        var data = {
                                            name      : this.state.name,
                                            gltf      : this.state.gltf,
                                            thumbnail : this.state.thumbnail,
                                            shape_svg : this.state.shape_svg,
                                            width     : this.state.width,
                                            height    : this.state.height,
                                            length      : this.state.length,
                                        }
                                        setFieldsValue({...data})}}
                                >
                                    <div
                                        tabIndex={0}
                                        role="button"
                                    >
                                        <Title level={3} className="pt-16" style={{textAlign: "center"}}>
                                            { type === "new" ? "Add" : "Edit"} Lighting
                                        </Title>
                                        <Divider />
                                        <Form layout="vertical" style={{marginTop: "20px"}}>
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                    <FormItem 
                                                        key="name"
                                                        {...formItemLayout}
                                                        label="Lighting Name"
                                                    >
                                                        {getFieldDecorator("name", {
                                                            initialValue: name,
                                                            rules: [ {
                                                                required: true, message: 'Enter Lighting.',
                                                            }],
                                                        })(
                                                            (<Input
                                                                placeholder="Enter Lighting Name"
                                                                onChange={(e)=> {
                                                                    this.setState({name: e.target.value});
                                                                }}
                                                            />)
                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                    <FormItem 
                                                        key="gltf"
                                                        {...formItemLayout}
                                                        label="Gltf & Bin file"
                                                    >
                                                        {getFieldDecorator("gltf", {
                                                            initialValue: gltf,
                                                            rules: [{ required: true, message: 'Please upload .gltf file!' }],
                                                            valuePropName: 'fileList',
                                                            getValueFromEvent: this.normFile,
                                                        })(
                                                            ( <Dragger {...props}
                                                                onChange={info => {
                                                                    const { status } = info.file;
                                                                    if (status !== 'uploading') {
                                                                        this.setState({gltf:info.fileList});
                                                                    }
                                                                    if (status === 'done') {
                                                                    message.success(`${info.file.name} file uploaded successfully.`);
                                                                    } else if (status === 'error') {
                                                                    message.error(`${info.file.name} file upload failed.`);
                                                                    }
                                                                }}
                                                                beforeUpload={fileList => {
                                                                    return false;
                                                                }}
                                                                >
                                                                <p className="ant-upload-drag-icon">
                                                                <Icon type="inbox" />
                                                                </p>
                                                                <p className="ant-upload-text">Click or drag .gltf & .bin files to this area to upload</p>
                                                                <p className="ant-upload-hint">
                                                                </p>
                                                            </Dragger>)
                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                    <FormItem 
                                                        key="Thumbnail"
                                                        {...formItemLayout}
                                                        label="Thumbnail"
                                                    >
                                                        {getFieldDecorator("thumbnail", {
                                                            initialValue: thumbnail,
                                                            valuePropName: 'fileList',
                                                            rules: [{ required: true, message: 'Please upload Thumbnail!' }],
                                                            getValueFromEvent: this.normFile,
                                                        })(
                                                            ( <Upload
                                                                listType="picture-card"
                                                                className="upload-list-inline"
                                                                onRemove={file => {
                                                                    this.setState({
                                                                        thumbnail: [],
                                                                    });
                                                                }}
                                                                beforeUpload={(file, fileList) => {
                                                                    this.setState({
                                                                        thumbnail: fileList,
                                                                    });
                                                                    return false;
                                                                }}
                                                                >
                                                                {thumbnail.length > 0 ? null : uploadButton}
                                                            </Upload>)
                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                    <FormItem 
                                                        key="shape_svg"
                                                        {...formItemLayout}
                                                        label="Shape(SVG)"
                                                    >
                                                        {getFieldDecorator("shape_svg", {
                                                            initialValue: shape_svg,
                                                            valuePropName: 'fileList',
                                                            getValueFromEvent: this.normFile,
                                                        })(
                                                            ( <Upload
                                                                listType="picture-card"
                                                                className="upload-list-inline"
                                                                onRemove={file => {
                                                                    this.setState({
                                                                        shape_svg: [],
                                                                    });
                                                                }}
                                                                beforeUpload={(file, fileList) => {
                                                                    this.setState({
                                                                        shape_svg: fileList,
                                                                    });
                                                                    return false;
                                                                }}
                                                                >
                                                                {shape_svg.length == 0 && uploadButton}
                                                            </Upload>)
                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                    <FormItem
                                                        key="width"
                                                        {...formItemLayout} 
                                                        label="Width"
                                                    >
                                                        {getFieldDecorator('width', {
                                                            initialValue: {number: width, currency: width_unit },
                                                            rules: [{ validator: this.checkNumber }]
                                                        })(
                                                            (  <UnitInput /> )
                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                <FormItem
                                                    key="length"
                                                    {...formItemLayout} 
                                                    label="Depth"
                                                >
                                                    {getFieldDecorator('length', {
                                                        initialValue: { number: length, currency: length_unit },
                                                        rules: [{ validator: this.checkNumber }]
                                                        })(
                                                        ( <UnitInput /> )
                                                    )}
                                                </FormItem>
                                                </Col>
                                            </Row>
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                <FormItem
                                                    key="height"
                                                    {...formItemLayout} 
                                                    label="Height"
                                                >
                                                    {getFieldDecorator('height', {
                                                        initialValue: {number: height, currency: height_unit },
                                                        rules: [{ validator: this.checkNumber }]
                                                        })(
                                                        ( <UnitInput /> )
                                                        )}
                                                </FormItem>
                                                </Col>
                                            </Row>
                                        </Form>  
                                        <div
                                            style={{
                                                position: 'absolute',
                                                left: 0,
                                                bottom: 0,
                                                width: '100%',
                                                borderTop: '1px solid #e9e9e9',
                                                padding: '10px 16px',
                                                background: '#fff',
                                                textAlign: 'right',
                                            }}
                                        >
                                            <Button onClick={this.toggleDrawer(null, null, false)} style={{ marginRight: 8 }}>
                                                Cancel
                                            </Button>
                                            <Button 
                                                onClick={this.handleSubmit} 
                                                variant="contained" 
                                                color="primary" 
                                                // disabled={!this.canBeSubmitted()}
                                            >
                                                {type === "new" ? "ADD" : "UPDATE"}
                                            </Button>
                                        </div>
                                    </div>
                                </Drawer>
                                <Table 
                                    bordered
                                    rowKey="id"
                                    className="mtable"
                                    pagination={defaultPagination}
                                    columns={columns} 
                                    dataSource={lighting}
                                    indentSize={20}
                                    scroll={{x: 2300, y: 500}}
                                />
                            </Row>
                        </div>
                    }
                />
            </Spin>
        )
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        getLighting: Actions.getLighting,
        addLighting: Actions.addLighting,
        setSortOrder: Actions.setSortOrder,
        deleteLighting: Actions.deleteLighting,
        updateLighting: Actions.updateLighting,
    }, dispatch);
}

function mapStateToProps({lighting, fuse})
{
    return {
        lighting: lighting.lighting.lighting,
        isLoading:fuse.loading.isLoading
    }
}
const LightingComponent = Form.create()(Lighting);

export default withReducer('lighting', reducer)(withStyles(styles, {withTheme: true})(withRouter(connect(mapStateToProps,mapDispatchToProps)(LightingComponent))));