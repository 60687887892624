import React, { Component } from 'react'
import { Button, Drawer, Divider} from '@material-ui/core';
import { Upload, Typography, Form, Row, Col, Icon } from 'antd';
import { withStyles } from '@material-ui/core/styles';

const { Title } = Typography;
const FormItem = Form.Item
const formItemLayout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 12 },
};

const { Dragger } = Upload;

const styles = theme => ({
    button: {
        marginTop: "-12px",
    },
    rightIcon: {
        marginLeft: '10px',
    },
    input: {
        display: 'none',
    },
    list: {
        width: 700,
    }
});

class RTALiveImportDrawerComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            csv: []
        }
    }

    handleSubmitCsv = () => {
        if(this.state.csv.length == 0){
            this.props.showMessage({
                message         : 'Not csv file',
                autoHideDuration: 2000,
                anchorOrigin    : {
                    vertical  : 'top',
                    horizontal: 'right'
                },variant : 'error'
            });
            return;
        }
        else{
            this.props.importCSV(this.state.csv);
            this.setState({csv:[]});
            this.props.onClose();
        }    
    }

    isCsvFile = (fileList) => {
        if(!fileList.name.endsWith('.csv'))
        {
            return true;
        }
        return false;
    }

    normFile = e => {
        if (Array.isArray(e)) {
          return e;
        }
        return e && e.fileList;
    }

    render() {

        const props = {
            name: 'file',
            multiple: true,
        };
        const { getFieldDecorator, setFieldsValue } = this.props.form;
        const { drawerClasses, open, anchor } = this.props;

        return (
            <Drawer
                style={{zIndex: 500}}
                anchor={anchor}
                open={open}
                onClose={() => this.props.onClose()}
                classes={drawerClasses}
            >
                <div
                    tabIndex={0}
                    role="button"
                >
                    <Title level={3} className="pt-16" style={{textAlign: "center"}}>
                        RTALive
                    </Title>
                    <Divider />
                    <Form layout="vertical" style={{marginTop: "20px"}}>
                    <Row gutter={8}>
                            <Col span={2} />
                            <Col span={22}>
                                <FormItem 
                                    key="csv"
                                    {...formItemLayout}
                                    label="Csv file"
                                >
                                    {getFieldDecorator("csv", {
                                        initialValue: this.state.csv,
                                        rules: [{ required: true, message: 'Please upload .csv file!' }],
                                        valuePropName: 'fileList',
                                        getValueFromEvent: this.normFile,
                                    })(
                                        (   <Dragger {...props}
                                                showUploadList={true}
                                                onChange={info => {
                                                    const fData = info.fileList.filter(e => e.name.endsWith('.csv'));
                                                    this.setState({csv: fData});
                                                }}
                                                beforeUpload={fileList => {
                                                    return this.isCsvFile(fileList);
                                                }}
                                            >
                                                <p className="ant-upload-drag-icon">
                                                <Icon type="inbox" />
                                                </p>
                                                <p className="ant-upload-text">.csv files to upload</p>
                                                <p className="ant-upload-hint">
                                                </p>
                                            </Dragger>
                                        )
                                    )}
                                </FormItem>
                                </Col>
                        </Row>
                        <Row>
                        <div
                            style={{
                                left: 0,
                                bottom: 0,
                                width: '100%',
                                borderTop: '1px solid #e9e9e9',
                                padding: '10px 16px',
                                background: '#fff',
                                textAlign: 'right',
                            }}
                        >
                            <Button 
                                onClick={() => {
                                    this.props.onClose();
                                }} 
                                style={{ marginRight: 8 }}>
                                Cancel
                            </Button>
                            <Button 
                                onClick={() => this.handleSubmitCsv()} 
                                variant="contained" 
                                color="primary" 
                            >
                                {"ADD"}
                            </Button>
                        </div>
                        </Row>
                    </Form>
                </div>
            </Drawer>
        )
    }
}

const RTALiveImportDrawer = Form.create()(RTALiveImportDrawerComponent);
export default (withStyles(styles, {withTheme: true})(RTALiveImportDrawer));