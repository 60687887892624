import * as Actions from "../actions";

const initialState = {
  waiting: false
};

export default (state = initialState, action) => {
  switch(action.type) {
    case Actions.CHANGE_WATING_STATE:
      return { waiting: action.payload };
    default:
      return state;
  }
};