import WallColor from './WallColor';
import {authRoles} from 'app/auth';
export const WallColorConfig = {
    settings: {
        layout: {
        }
    },
    auth    : authRoles.designer,
    routes  : [
        {
            path     : '/finishing_touch/wall_color',
            component: WallColor
        }
    ]
};