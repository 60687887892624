import axios from 'axios';
import { showMessage } from 'app/store/actions/fuse';
import { API_SERVER_URL } from 'app/main/config';
import { uploadLoading } from 'app/store/actions/fuse';

export const GET_RTALIVE = '[RTALIVE] GET RTALIVE';

export function getRTAlive() {
    return (dispatch => {
        axios.post(`${API_SERVER_URL}/api/magento/read/rtalive`)
            .then(response => {
                const { success, data } = response.data;
                if(success) {
                    dispatch({
                        type: GET_RTALIVE,
                        rtalive: data,
                    });
                } else {
                    dispatch(showMessage({
                        message: 'Failed to load RTALIVE data',
                        autoHideDuration: 2000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                        variant: 'error',
                    }));
                }
            })
            .catch(err => {
                dispatch(showMessage({
                    message: 'Failed to load RTALIVE data',
                    autoHideDuration: 2000,
                    anchorOrigin: {
                        vertical  : 'top',
                        horizontal: 'right'
                    },
                    variant: 'error',
                }));
                console.log(err);
            })
    });
}

export function importCSV(data) {
    const formData = new FormData();
    formData.append("csv", data[0].originFileObj);
    return ((dispatch) => {
        dispatch(uploadLoading(true));
        axios.post(`${API_SERVER_URL}/api/magento/import_csv`, formData)
            .then(response => {
                dispatch(uploadLoading(false));
                const { success, errno, errmsg } = response.data;
                if (success === true) {
                    dispatch(showMessage({
                        message: 'Successfully Uploaded',
                        autoHideDuration: 2000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }, variant: 'success'
                    }));
                }
                else {
                    let errMessage = 'Unknown Error';
                    let errType = 'error';

                    switch (errno) {
                        case 101:
                        case 102:
                        case 103:
                            errMessage = errmsg;
                            break;
                    }
                    dispatch(showMessage({
                        message: errMessage,
                        autoHideDuration: 2000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }, variant: errType
                    }));
                }
            }).catch(err => {
                console.log(err);
                dispatch(showMessage({
                    message: 'Failed to Upload',
                    autoHideDuration: 2000,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }, variant: 'error'
                }));
            });
    })
}
