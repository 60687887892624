import axios from 'axios';
import { API_SERVER_URL } from 'app/main/config';
import {showMessage} from 'app/store/actions/fuse';

export const GET_MANUFACTURER = '[MANUFACTURER] GET MANUFACTURER';
export const DELETE_MANUFACTURER = '[MANUFACTURER] DELETE MANUFACTURER';
export const OPEN_NEW_USER_DIALOG = '[MANUFACTURER] OPEN NEW USER DIALOG';
export const CLOSE_NEW_USER_DIALOG = '[MANUFACTURER] CLOSE NEW USER DIALOG';
export const OPEN_EDIT_USER_DIALOG = '[MANUFACTURER] OPEN EDIT USER DIALOG';
export const CLOSE_EDIT_USER_DIALOG = '[MANUFACTURER] CLOSE EDIT USER DIALOG';
export const ADD_USER = '[MANUFACTURER] ADD USER';
export const UPDATE_USER = '[MANUFACTURER] UPDATE USER';

export function getManufacturer() {
    return (dispatch => {
        axios.post(`${API_SERVER_URL}/api/manufacturer/read/manufacturer`)
        .then(response => {
            const {manufacturerData, success} = response.data;
            if(success === true) {
                dispatch({
                    type:GET_MANUFACTURER,
                    payload: manufacturerData,
                });
            }
        }).catch(err=>{
            dispatch(showMessage({
                message         : 'Failed to load MANUFACTURER data',
                autoHideDuration: 2000,
                anchorOrigin    : {
                    vertical  : 'top',
                    horizontal: 'right'
                },variant : 'error'
            }));
            console.log("err", err);
        });        
    });
    
}

export function deleteManufacturer(id) {
    return (dispatch => {
        axios.post(`${API_SERVER_URL}/api/manufacturer/delete/manufacturer`,{
            id
        })
        .then(response => {
            const {manufacturerData, success} = response.data;
            if(success === true) {
                dispatch(showMessage({
                    message         : 'Successfully Deleted',
                    autoHideDuration: 2000,
                    anchorOrigin    : {
                        vertical  : 'top',
                        horizontal: 'right'
                    },variant : 'success'
                }));
                dispatch({
                    type:DELETE_MANUFACTURER,
                    payload: manufacturerData,
                });
            }
        }).catch(err => {
            dispatch(showMessage({
                message         : 'Failed to delete MANUFACTURER data',
                autoHideDuration: 2000,
                anchorOrigin    : {
                    vertical  : 'top',
                    horizontal: 'right'
                },variant : 'error'
            }));

            console.log("err", err);
        });        
    });
}

export function openNewUserDialog()
{
    return {
        type: OPEN_NEW_USER_DIALOG
    }
}

export function closeNewUserDialog()
{
    return {
        type: CLOSE_NEW_USER_DIALOG
    }
}

export function openEditUserDialog(data)
{
    return {
        type: OPEN_EDIT_USER_DIALOG,
        data
    }
}

export function closeEditUserDialog()
{
    return {
        type: CLOSE_EDIT_USER_DIALOG
    }
}

export function addUser(data)
{
    return (dispatch) => {
        const request = axios.post(`${API_SERVER_URL}/api/manufacturer/insert/manufacturer`, {
            data
        });

        return request.then((response) =>
        {   
            dispatch(showMessage({
                message         : 'Successfully Inserted',
                autoHideDuration: 2000,
                anchorOrigin    : {
                    vertical  : 'top',
                    horizontal: 'right'
                },variant : 'success'
            }));
            Promise.all([
                dispatch({
                    type: ADD_USER
                })
            ]).then(() => dispatch(getManufacturer()))
        }
        ).catch(err => {
            dispatch(showMessage({
                message         : 'Failed to add MANUFACTURER data',
                autoHideDuration: 2000,
                anchorOrigin    : {
                    vertical  : 'top',
                    horizontal: 'right'
                },variant : 'error'
            }));

            console.log("err", err);
        });        ;
        
    };
}

export function updateUser(data)
{
    return (dispatch) => {
        const request = axios.post(`${API_SERVER_URL}/api/manufacturer/update/manufacturer`, {
            data
        });

        return request.then((response) =>{
            dispatch(showMessage({
                message         : 'Successfully Updated',
                autoHideDuration: 2000,
                anchorOrigin    : {
                    vertical  : 'top',
                    horizontal: 'right'
                },variant : 'success'
            }));
            Promise.all([
                dispatch({
                    type: UPDATE_USER
                })
            ]).then(() => dispatch(getManufacturer()))
        }
        ).catch(err => {
            dispatch(showMessage({
                message         : 'Failed to update MANUFACTURER data',
                autoHideDuration: 2000,
                anchorOrigin    : {
                    vertical  : 'top',
                    horizontal: 'right'
                },variant : 'error'
            }));

            console.log("err", err);
        });        ;
    };
}
