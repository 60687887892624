import * as Actions from '../actions';

const initialState = {
    appliance: [],
    applianceCategory: [],
    modalData: undefined
};

const applianceReducer = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.GET_APPLIANCE:
        {
            return {
                ...state,
                ...action,
            }   
        }
        case Actions.GET_APPLIANCE_CATEGORY:
        {
            return {
                ...state,
                ...action
            }
        }
        default:
        {
            return state;
        }
    }
};

export default applianceReducer;
