import React, {Component} from 'react';
import * as Actions from './store/actions';
import reducer from './store/reducers';
import {withRouter} from 'react-router-dom'
import withReducer from 'app/store/withReducer';
import { withStyles } from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {FusePageCarded, FuseAnimate} from '@fuse';
import {IconButton, Button, Drawer, MenuItem, Divider} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import {Table, Modal,Typography} from 'antd';
import Formsy from 'formsy-react';
import * as appActions from 'app/store/actions';
import {TextFieldFormsy, SelectFormsy} from '@fuse';
import _ from '@lodash';

const {Title} = Typography;
const { confirm } = Modal;  

const styles = theme => ({
    input: {
        display: 'none',
    },
    list: {
        width: 500,
    },
});

var defaultPagination = {
    pageSizeOptions: ['5', '10', '50', '250'],
    showSizeChanger: true,
    size: 'small',
    position: 'top',
}

const newUserState = {
    id      : '',
    collection_name: '',
    manufacturer_id: '',
};

class Collection extends Component {

    state = {
        ...newUserState,
        canSubmit:false
    };

    componentDidMount()
    {
        this.props.getCollection();
        this.props.getManufacturer();
    }

    handleDeleteEvent = (record) => {
            const {deleteCollection} = this.props;
            confirm({
                title: 'Do you want to delete this Item?',
                onOk() {
                    deleteCollection(record.id)
                },
                onCancel() {},
            });

        // {
        //     this.props.showMessage({
        //         message: 'Sorry, you should delete item from Manufacturer', 
        //         autoHideDuration: 2000,anchorOrigin:{
        //             vertical  : 'top',
        //             horizontal: 'right'
        //     },variant : 'warning'});
        // }
    }

    componentDidUpdate(prevProps, prevState)
    {
        /**
         * After Dialog Open
         */
        if ( !prevProps.userDialog.props.open && this.props.userDialog.props.open )
        {
            /**
             * Dialog type: 'edit'
             * Update State
             */
            
            if ( this.props.userDialog.type === 'edit' &&
                this.props.userDialog.data &&
                !_.isEqual(this.props.userDialog.data, prevState) )
            {
                this.setState({...this.props.userDialog.data});
            }

            /**
             * Dialog type: 'new'
             * Update State
             */
            if ( this.props.userDialog.type === 'new' &&
                !_.isEqual(newUserState, prevState) )
            {
                this.setState({...newUserState});
            }
        }
    }

    closeComposeDialog = () => {
        this.props.userDialog.type === 'edit' ? this.props.closeEditUserDialog() : this.props.closeNewUserDialog();
        this.setState({checked: false});
    };

    disableButton = () => {
        this.setState({canSubmit: false});
    };

    enableButton = () => {
        this.setState({canSubmit: true});
    };

    onSubmit = (model) => {
       
        if(this.props.userDialog.type === "new") {
            this.props.addCollection(model);
            this.props.closeNewUserDialog();
        } else{
            var user = {
                id : this.props.userDialog.data.id,
                name: model.name,
                manufacturer_id: model.manufacturer_id,
            }
            
            this.props.updateCollection(user);
            this.props.closeEditUserDialog();
        }
        
    };

    render()
    {
        const {classes, openNewUserDialog, collectionData, manufacturerData, userDialog, openEditUserDialog} = this.props;
        const {canSubmit} = this.state;
        const menuItems = [];
        manufacturerData.forEach(fElement => {
            menuItems.push(<MenuItem key={fElement.id} value={fElement.id}>{fElement.name}</MenuItem>);
        })
        const data = collectionData.map((obj,index) => ({
            ...obj,
            key : index
        }));
       
        const columns = [
            {
              title: 'Name',
              dataIndex: 'collection_name',
              key: 'collection',
              width: 300,
            },
            {
                title: 'Manufacturer',
                dataIndex: 'manufacturer_name',
                key: 'manufacturer',
              },
            {
                title: 'Action',
                dataIndex: 'id',
                width: 150,
                key: 'action',
                render: (text, record) => (
                    <div>
                        <IconButton title="Edit" aria-label="Edit" onClick={e=> {openEditUserDialog(record)}}>
                            <EditIcon />
                        </IconButton>
                        <IconButton title="Delete" aria-label="Delete" onClick={e=> {e.preventDefault(); this.handleDeleteEvent(record);}}>
                            <DeleteIcon />
                        </IconButton>
                    </div>
                ),
            },
        ];
        return (
            <React.Fragment>
                <FusePageCarded
                    classes={{
                        toolbar: "p-0",
                        header : "min-h-72 h-72 sm:h-136 sm:min-h-136"
                    }}
                    header={
                        <div className="flex flex-1 w-full items-center justify-between">
                            <div className="flex flex-col items-start max-w-full">
                                <div className="flex items-center max-w-full">
                                    <div className="flex flex-col min-w-0">
                                        <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                                            <Title level={4} className="text-16 sm:text-20 truncate">
                                            </Title>
                                        </FuseAnimate>
                                    </div>
                                </div>
                            </div>
                            <FuseAnimate animation="transition.slideRightIn" delay={300}>
                                <Button
                                    className="whitespace-no-wrap"
                                    variant="contained"
                                    onClick={openNewUserDialog}
                                >
                                    ADD
                                </Button>
                            </FuseAnimate>
                        </div>
    
                    }
                    contentToolbar={
                        <Title level={4} style={{marginLeft: "25px"}} className="text-16 sm:text-20 truncate" classes={{root: "w-full h-64"}}>
                            {"Collection"}
                        </Title>
                    }
                    content={
                        <div className="p-24">
                            <Drawer
                                anchor="right"
                                {...userDialog.props}
                                variant="temporary"
                                onClose={this.closeComposeDialog}
                                classes={{paper: classes.list}}
                                
                            >
                                <div
                                    tabIndex={0}
                                    role="button"
                                >
                                    <Title level={3} className="pt-16" style={{textAlign: "center"}}>
                                        { userDialog.type === "new" ? "Add" : "Edit"} Collection
                                    </Title>
                                    <Divider />
                                     
                                    <Formsy
                                        className="m-20"
                                        onValidSubmit={this.onSubmit}
                                        onValid={this.enableButton}
                                        onInvalid={this.disableButton}
                                        ref={(form) => this.form = form}
                                    >
                                        <TextFieldFormsy
                                            className="w-full mb-24"
                                            type="text"
                                            name="name"
                                            label="Collection Name"
                                            value={this.state.collection_name}
                                            validations={{
                                                minLength: 1
                                            }}
                                            variant="outlined"
                                            required
                                            
                                                            
                                        />
                                        <SelectFormsy
                                            className="w-full mb-24"
                                            name="manufacturer_id"
                                            label="Manufacturer Name"
                                            required
                                            variant="outlined"
                                            value={this.state.manufacturer_name ? this.state.manufacturer_id :""}
                                        >
                                            <MenuItem value="">None</MenuItem>
                                            {menuItems}
                                        </SelectFormsy>
                                        <div
                                            style={{
                                                position: 'absolute',
                                                left: 0,
                                                bottom: 0,
                                                width: '100%',
                                                borderTop: '1px solid #e9e9e9',
                                                padding: '10px 16px',
                                                background: '#fff',
                                                textAlign: 'right',
                                            }}
                                        >
                                            <Button onClick={this.closeComposeDialog} style={{ marginRight: 8 }}>
                                                Cancel
                                            </Button>
                                            {userDialog.type === 'new' ? (
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    aria-label="REGISTER"
                                                    disabled={!canSubmit}
                                                    value="legacy"
                                                >
                                                    ADD
                                                </Button>
                                            ) : (
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    aria-label="REGISTER"
                                                    disabled={!canSubmit}
                                                    value="legacy"
                                                >
                                                    UPDATE
                                                </Button>
                                            )}   
                                        </div>
                                    </Formsy>
                                </div>
                            </Drawer>
                            <Table 
                                bordered
                                pagination={defaultPagination}
                                className="mtable"
                                columns={columns} 
                                dataSource={data} />
                        </div>
                    }
                />
            </React.Fragment>
            
        )
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        getCollection: Actions.getCollection,
        getManufacturer: Actions.getManufacturer,
        deleteCollection: Actions.deleteCollection,
        openNewUserDialog: Actions.openNewUserDialog,
        openEditUserDialog: Actions.openEditUserDialog,
        removeUserDialog: Actions.removeUserDialog,
        closeEditUserDialog: Actions.closeEditUserDialog,
        closeNewUserDialog : Actions.closeNewUserDialog,
        addCollection            : Actions.addCollection,
        updateCollection         : Actions.updateCollection,
        showMessage     : appActions.showMessage,
    }, dispatch);
}

function mapStateToProps({collectionApp})
{
    return {
        collectionData: collectionApp.collection.collectionData,
        manufacturerData: collectionApp.collection.manufacturerData,
        userDialog: collectionApp.collection.userDialog
    }
}


export default withReducer('collectionApp', reducer)(withRouter(connect(mapStateToProps,mapDispatchToProps)(withStyles(styles, {withTheme: true})(Collection))));
