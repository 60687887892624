import axios from 'axios';
import { showMessage } from 'app/store/actions/fuse';
import { API_SERVER_URL, jsonArray, getData, uploadFiles, putFiles, deleteFiles, canUpload, shapeSVGValue } from 'app/main/config';
import { uploadLoading }from 'app/store/actions/fuse';

export const GET_APPLIANCE_CATEGORY = '[APPLIANCE] GET APPLIANCE CATEGORY';
export const GET_APPLIANCE = '[APPLIANCE] GET APPLIANCE';
export const ADD_APPLIANCE = '[APPLIANCE] ADD APPLIANCE';
export const DELETE_APPLIANCE = '[APPLIANCE] DELETE APPLIANCE';
export const UPDATE_APPLIANCE = '[APPLIANCE] UPDATE APPLIANCE';

export function getApplianceCategory() {
    return (dispatch => {
        axios.post(`${API_SERVER_URL}/api/applianceCategory/read/appliance_category`)
        .then(response => {
            const {applianceCategory, success} = response.data;
            if(success === true) {                
                var Category = getData(applianceCategory, 'parent_category_id', 0);
                dispatch({
                    type:GET_APPLIANCE_CATEGORY,
                    applianceCategory: Category,
                })
            }
        })    
    })
}

export function getAppliance() {
    return (dispatch => {
        axios.post(`${API_SERVER_URL}/api/appliance/read/appliance`)
        .then(response => {
            const {appliance} = response.data;
                dispatch({
                    type:GET_APPLIANCE,
                    appliance: appliance,
                })
        }).catch(err=>{
            dispatch(showMessage({
                message         : 'Failed to load APPLIANCE data',
                autoHideDuration: 2000,
                anchorOrigin    : {
                    vertical  : 'top',
                    horizontal: 'right'
                },variant : 'error'
            }));
            console.log("err", err);
        });    
    })
}

export function addAppliance(data) {
    var data_gltf = {
        'gltf' : jsonArray(data.gltf)
    };
    var data_string = JSON.stringify(data_gltf);
    var newData = {
        name: data.appliance_name,
        appliance_category_id: data.category_id,
        width: data.width,
        height: data.height,
        length : data.length,
        thumbnail: data.thumbnail[0].name,
        shape_svg: shapeSVGValue(data.shape_svg),
        gltf : data_string,
        comments: data.comments,
    }
    return ((dispatch) => {
        axios.post(`${API_SERVER_URL}/api/appliance/insert/appliance`, {
            ...newData
        })
        .then(res => {
            dispatch(uploadLoading(true));
            if(process.env.REACT_APP_MODE === "production") {
                uploadFiles(data.gltf.filter(file => file.originFileObj !== undefined), `uploads/Appliance/${res.data.data.guid + '_' + res.data.data.id}/gltf/`).then(gltf => {
                    uploadFiles(data.thumbnail.filter(file => file.originFileObj !== undefined), `uploads/Appliance/${res.data.data.guid + '_' + res.data.data.id}/thumbnail/`).then(thumbnail => {
                        uploadFiles(data.shape_svg.filter(file => file.originFileObj !== undefined), `uploads/Appliance/${res.data.data.guid + '_' + res.data.data.id}/shape_svg/`).then(shape_svg => {
                            axios.post(`${API_SERVER_URL}/api/base/updateUploadData`, 
                                {
                                    id: res.data.data.id,
                                    tableName: "appliance",
                                    data: [ 
                                        {
                                            fieldName: 'gltf',
                                            type: 'multi',
                                            data: [ 
                                                {
                                                    jsonFieldName: 'gltf',
                                                    data: gltf,
                                                }
                                            ]
                                        }, 
                                        {
                                            fieldName: 'thumbnail',
                                            type: 'single',
                                            data: thumbnail
                                        },
                                        {
                                            fieldName: 'shape_svg',
                                            type: 'single',
                                            data: shape_svg
                                        },
                                    ]
                                }
                            )
                            .then(response => {
                                const {success} = response.data;
                                dispatch(uploadLoading(false));
                                if(success === true) {

                                    dispatch(showMessage({
                                        message         : 'Successfully Inserted',
                                        autoHideDuration: 2000,
                                        anchorOrigin    : {
                                            vertical  : 'top',
                                            horizontal: 'right'
                                        },variant : 'success'
                                    }));
                                    Promise.all([
                                        dispatch({
                                            type: ADD_APPLIANCE
                                        })
                                    ]).then(() => dispatch(getAppliance()))
                                } else {
                                    console.log(success);
                                }
                            })
                            .catch(err => {
                                console.log("failed to save uploaded data in insert action", err);
                                return;
                            })
                        })
                        .catch(err => {
                            console.log("failed to upload appliance shape(svg). ", err);
                            return;
                        })
                    })
                    .catch(err => {
                        console.log("failed to upload appliance thumbnail. ", err);
                        return;
                    })
                })
                .catch(err => {
                    console.log("failed to upload appliance gltfs. ", err);
                    return;
                })
            }
            else {
                const formData = new FormData();
                formData.append("thumbnail", data.thumbnail[0].originFileObj);
                data.shape_svg.forEach(fElement => {
                    formData.append("shape_svg", fElement.originFileObj);
                })
                data.gltf.forEach(fElement => {
                    formData.append("gltf", fElement.originFileObj);
                })
                formData.append("guid" , res.data.data.guid);
                formData.append("id" , res.data.data.id);
                axios.post(`${API_SERVER_URL}/api/appliance/upload`, formData)
                .then(response => {
                    dispatch(uploadLoading(false));
                    const {success} = response.data;
                    if(success === true) {
                        axios.post(`${API_SERVER_URL}/uploads/clear`)
                        .then(res => {
                            if(res.data.success === true ){
                                dispatch(showMessage({
                                    message         : 'Successfully Inserted',
                                    autoHideDuration: 2000,
                                    anchorOrigin    : {
                                        vertical  : 'top',
                                        horizontal: 'right'
                                    },variant : 'success'
                                }));
                                Promise.all([
                                    dispatch({
                                        type: ADD_APPLIANCE
                                    })
                                ]).then(() => dispatch(getAppliance()))
                            }
                        })
                    }
                })
            }
        }).catch(err => {
            console.log(err);
            dispatch(showMessage({
                message         : 'Failed to add APPLIANCE data',
                autoHideDuration: 2000,
                anchorOrigin    : {
                    vertical  : 'top',
                    horizontal: 'right'
                },variant : 'error'
            }));
        });
    })

}

export function deleteAppliance(record) {
    return ((dispatch) => {
        var p1 = new Promise((resolve, reject) => {
            dispatch(uploadLoading(true));
            if(process.env.REACT_APP_MODE === "production" ) {
                const {gltf} = JSON.parse(record.gltf);
                gltf.push(record.thumbnail);
                if (record.shape_svg) gltf.push(record.shape_svg);
                deleteFiles(gltf).then(res => {
                    resolve("success");
                })
                .catch(err => {
                    console.log("failed to delete appliace.", err);
                    reject(err);
                })
            } else {
                resolve("success");
            }
        })
        p1.then(p1Value => {
            axios.post(`${API_SERVER_URL}/api/appliance/remove/appliance`, {
                id: record.id,
                appliance_door_style_ids: record.appliance_door_style_ids,
            })
            .then(response => {
                const {success} = response.data;
                dispatch(uploadLoading(false));
                if(success === true) {
                    dispatch(showMessage({
                        message         : 'Successfully Deleted',
                        autoHideDuration: 2000,
                        anchorOrigin    : {
                            vertical  : 'top',
                            horizontal: 'right'
                        },variant : 'success'
                    }));
                    Promise.all([
                        dispatch({
                            type: DELETE_APPLIANCE
                        })
                    ]).then(() => dispatch(getAppliance()))
                }
            }).catch(err => {
                dispatch(uploadLoading(false));
                dispatch(showMessage({
                    message         : 'Failed to delete APPLIANCE data',
                    autoHideDuration: 2000,
                    anchorOrigin    : {
                        vertical  : 'top',
                        horizontal: 'right'
                    },variant : 'error'
                }));
            });    
        })
        .catch(err => {
            dispatch(uploadLoading(false));
            dispatch(showMessage({
                message         : 'AWS S3 DELETE APPLIANCE ERROR',
                autoHideDuration: 2000,
                anchorOrigin    : {
                    vertical  : 'top',
                    horizontal: 'right'
                },variant : 'error'
            }));
            return;
        })
    })
}

export function updateAppliance(data, id, guid) {
    var data_gltf = {
        'gltf' : jsonArray(data.gltf)
    };
    var data_string = JSON.stringify(data_gltf);
    var updateData = {
        id: id,
        name: data.appliance_name,
        appliance_category_id: data.category_id,
        width: data.width,
        height: data.height,
        length : data.length,
        thumbnail: jsonArray([data.thumbnail[0]]),
        shape_svg: shapeSVGValue(data.shape_svg),
        gltf : data_string,
        comments: data.comments,
    }

    return ((dispatch) => {
        axios.post(`${API_SERVER_URL}/api/appliance/update/appliance`, {
            ...updateData
        })
        .then(res => {
            dispatch(uploadLoading(true));
            if(process.env.REACT_APP_MODE === "production") {
                putFiles(data.gltf.filter(file => file.originFileObj !== undefined), `uploads/Appliance/${guid + '_' + id}/gltf/`).then(gltf => {
                    putFiles(data.thumbnail.filter(file => file.originFileObj !== undefined), `uploads/Appliance/${guid + '_' + id}/thumbnail/`).then(thumbnail => {                        
                        putFiles(data.shape_svg.filter(file => file.originFileObj !== undefined), `uploads/Appliance/${guid + '_' + id}/shape_svg/`).then(shape_svg => {
                            axios.post(`${API_SERVER_URL}/api/base/updateUploadData`, 
                                {
                                    id: id,
                                    tableName: "appliance",
                                    data: [ 
                                        {
                                            fieldName: 'gltf',
                                            type: 'multi',
                                            data: [ 
                                                {
                                                    jsonFieldName: 'gltf',
                                                    data: gltf,
                                                }
                                            ]
                                        }, 
                                        {
                                            fieldName: 'thumbnail',
                                            type: 'single',
                                            data: thumbnail
                                        },
                                        {
                                            fieldName: 'shape_svg',
                                            type: 'single',
                                            data: shape_svg
                                        },
                                    ]
                                }
                            )
                            .then(response => {
                                const {success} = response.data;
                                dispatch(uploadLoading(false));
                                if(success === true) {
                                    dispatch(showMessage({
                                        message         : 'Successfully Updated',
                                        autoHideDuration: 2000,
                                        anchorOrigin    : {
                                            vertical  : 'top',
                                            horizontal: 'right'
                                        },variant : 'success'
                                    }));
                                    Promise.all([
                                        dispatch({
                                            type: UPDATE_APPLIANCE
                                        })
                                    ]).then(() => dispatch(getAppliance()))
                                } else {
                                    console.log(success);
                                }
                            })
                            .catch(err => {
                                console.log("failed to save uploaded data in update action", err);
                                return;
                            })
                        })
                        .catch(err => {
                            console.log("failed to put appliance shape(svg). ", err);
                            return;
                        })
                    })
                    .catch(err => {
                        console.log("failed to put appliance thumbnail. ", err);
                        return;
                    })
                })
                .catch(err => {
                    console.log("failed to put appliance gltfs. ", err);
                    return;
                });
            }
            else {
                if(data.thumbnail[0].originFileObj !== undefined || canUpload(data.shape_svg) === true || canUpload(data.gltf) === true) {
                    const formData = new FormData();
                    formData.append("guid" , guid);
                    formData.append("id" , id);
                    if(data.thumbnail[0].originFileObj !== undefined) {
                        formData.append("thumbnail", data.thumbnail[0].originFileObj);
                    }
                    if(canUpload(data.shape_svg)) {
                        data.shape_svg.forEach(fElement => {
                            formData.append("shape_svg", fElement.originFileObj);
                        })
                    }
                    if(canUpload(data.gltf)) {
                        data.gltf.forEach(fElement => {
                            formData.append("gltf",fElement.originFileObj);
                        })
                    }
                    axios.post(`${API_SERVER_URL}/api/appliance/upload`, formData)
                    .then(response => {
                        const {success} = response.data;
                        dispatch(uploadLoading(false));
                        if(success === true) {
                            axios.post(`${API_SERVER_URL}/uploads/clear`)
                            .then(res => {
                                if(res.data.success === true ){
                                    dispatch(showMessage({
                                        message         : 'Successfully Updated',
                                        autoHideDuration: 2000,
                                        anchorOrigin    : {
                                            vertical  : 'top',
                                            horizontal: 'right'
                                        },variant : 'success'
                                    }));
                                    Promise.all([
                                        dispatch({
                                            type: UPDATE_APPLIANCE
                                        })
                                    ]).then(() => dispatch(getAppliance()))
                                }
                            })
                        }
                    })
                    
                } else{
                    dispatch(uploadLoading(false));
                    dispatch(showMessage({
                        message         : 'Successfully Updated',
                        autoHideDuration: 2000,
                        anchorOrigin    : {
                            vertical  : 'top',
                            horizontal: 'right'
                        },variant : 'success'
                    }));
                    Promise.all([
                        dispatch({
                            type: UPDATE_APPLIANCE
                        })
                    ]).then(() => dispatch(getAppliance()))
                }   
            }
        })
        .catch(err => {
            console.log(err);
            dispatch(showMessage({
                message         : 'Failed to update APPLIANCE data',
                autoHideDuration: 2000,
                anchorOrigin    : {
                    vertical  : 'top',
                    horizontal: 'right'
                },variant : 'error'
            }));
        });
        
    })
}

export const SET_APPLIANCE_SORT_ORDER = '[APPLAINCE] SET SORT ORDER';
export function setSortOrder(data) {
    return ((dispatch) => {
        dispatch(uploadLoading(true));
        axios.post(`${API_SERVER_URL}/api/base/setSortOrder`, {
            id: data.id,
            sort_order: data.sort_order,
            table_name: 'appliance'
        })
        .then(response => {
            const {success} = response.data;
            if(success === true) {
                dispatch(uploadLoading(false));
                Promise.all([
                    dispatch({
                        type:SET_APPLIANCE_SORT_ORDER,
                    })  
                ]).then(() => dispatch(getAppliance()))
            } else {
                dispatch(uploadLoading(false));
            }
        })
        .catch(err => {
            dispatch(uploadLoading(false));
            dispatch(showMessage({
                message         : 'Failed to set sort order',
                autoHideDuration: 2000,
                anchorOrigin    : {
                    vertical  : 'top',
                    horizontal: 'right'
                },variant : 'error'
            }));
            console.log("failed to set sort order in appliance.", err);
        })
    })
}
