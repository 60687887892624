import React, { Component } from 'react';
import * as Actions from './store/actions';
import reducer from './store/reducers';
import { withRouter } from 'react-router-dom'
import withReducer from 'app/store/withReducer';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { IconButton, Drawer, Divider } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { FusePageCarded, FuseAnimate } from '@fuse';
import { Table, Modal, Typography, Select } from 'antd';
import Formsy from 'formsy-react';
import { TextFieldFormsy } from '@fuse';
import _ from '@lodash';
import { Button } from 'antd';
import ManufacturerSubgroupDrawer from './ManufacturerSubgroupDrawer';
import ManufacturerSubgroupTableModal from './ManufacturerSubgroupTableModal';
const {Option} = Select;

const { Title } = Typography;
const styles = theme => ({
    input: {
        display: 'none',
    },
    addButton: {
        position: 'absolute',
        right: 80,
        bottom: 70,
        zIndex: 99
    },
    list: {
        width: 500
    },
    list1: {
        width: 400
    },
});

var defaultPagination = {
    pageSizeOptions: ['5', '10', '50', '250'],
    showSizeChanger: true,
    size: 'small',
    position: 'top',
}

const { confirm } = Modal;
const newUserState = {
    id: '',
    name: ''
};

class Catalog extends Component {
    state = {
        ...newUserState,
        dueMenu: null,
        canSubmit: false,
        checked: false,
        anchorEl: null,
        selectedSubgroup: {},
        addSubgroupVisible: false,
        manufacturerType: "",
        subgroupTableVisible: false
    };

    componentDidMount() {
        this.props.getCatalog();
        this.props.getManufacturer();
    }

    handleDeleteEvent = (record) => {
        const { deleteCatalog } = this.props;
        confirm({
            title: 'Do you want to delete this Item?',
            onOk() {
                deleteCatalog(record.id)
            },
            onCancel() { },
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        /**
         * After Dialog Open
         */
        if (!prevProps.userDialog.props.open && this.props.userDialog.props.open) {
            /**
             * Dialog type: 'edit'
             * Update State
             */
            if (this.props.userDialog.type === 'edit' &&
                this.props.userDialog.data &&
                !_.isEqual(this.props.userDialog.data, prevState)) {
                this.setState({ ...this.props.userDialog.data });
            }

            /**
             * Dialog type: 'new'
             * Update State
             */
            if (this.props.userDialog.type === 'new' &&
                !_.isEqual(newUserState, prevState)) {
                this.setState({ ...newUserState });
                this.props.setManufacturer([]);
            }
        }
    }

    closeComposeDialog = () => {
        this.props.userDialog.type === 'edit' ? this.props.closeEditUserDialog() : this.props.closeNewUserDialog();
        this.setState({ checked: false });
    };

    disableButton = () => {
        this.setState({ canSubmit: false });
    };

    enableButton = () => {
        this.setState({ canSubmit: true });
    };

    onSubmit = () => {
        if (this.props.userDialog.type === "new") {
            this.props.addUser({name: this.state.name, manufacturer_subgroup_ids: this.props.manufacturerSubgroupData});
        } else {
            var user = {
                id: this.props.userDialog.data.id,
                name: this.state.name,
                manufacturer_subgroup_ids: this.props.manufacturerSubgroupData
            }
            this.props.updateUser(user);
        }
        this.props.closeEditUserDialog();
        this.props.setManufacturer([]);
    };
    updateCatalog = record => {
        this.props.setManufacturer(record.manufacturer_subgroup_ids[0] != null ? record.manufacturer_subgroup_ids: [])
        this.props.openEditUserDialog(record);
    }

    addManufacturerSubgroup = (type) => {
        this.setState({addSubgroupVisible: true, manufacturerType: type, selectedSubgroup: {}});
    }
    handleEditSubgroup = (record) => {
        this.setState({
            addSubgroupVisible: true,
            manufacturerType: "edit",
            selectedSubgroup: record
        });
    }

    handleDeleteSubgroup = (record) => {
        const {deleteManufacturer, catalog} = this.props;
        confirm({
            title: 'Do you want to delete this Item?',
            onOk() {
                let catalogItem = catalog.find(a => {
                    return a.manufacturer_subgroup_ids.some(element => {
                        return element == record.id;
                    });
                });

                if (catalogItem && catalogItem.id) {
                    deleteManufacturer({
                        catalogId: catalogItem.id,
                        id: record.id
                    });
                } else {
                    console.error("Catalog item or its id not found");
                }
            },
            onCancel() {},
        });
    }

    handleViewTable = () => {
        this.setState({
            subgroupTableVisible : true,
        });
    }

    render() {
        const { classes, openNewUserDialog, catalog, userDialog, subgroup, manufacturerData, colorAlias, colorFamilyData, addManufacturer, updateManufacturer, manufacturerSubgroupData } = this.props;
        const { canSubmit, addSubgroupVisible, manufacturerType, subgroupTableVisible, selectedSubgroup } = this.state;
        const data = catalog.map((obj, index) => {
            const manufacturer_subgroups = obj.manufacturer_subgroup_ids.map(item => {
                const foundSubgroup = subgroup.find(a => a.id == item);
                return foundSubgroup ? foundSubgroup.name : undefined;
            });
        
            return {
                ...obj,
                manufacturer_subgroups: manufacturer_subgroups,
                key: index
            };
        });
        const subgroupData = [];
        const menuItems = [];
        subgroup && subgroup.forEach(fElement => {
            menuItems.push(<Option key={fElement.id} value={fElement.id}>{fElement.name}</Option>);
            if(manufacturerSubgroupData.some(element => { return element == fElement.id})) {
                subgroupData.push({
                    ...fElement,
                    manufacturer_id: manufacturerData.find(a=>{return a.id == fElement.manufacturer_id}),
                    door_color_alias_ids: fElement.door_color_alias_ids.map(item=>(colorAlias.find(a=>{return a.id == item})))
                });
            }
        })

        const columns = [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: 'Manufacturer Subgroups',
                dataIndex: 'manufacturer_subgroups',
                key: 'manufacturer_subgroups',
                render: (txt, record) => {
                    return(
                        <Select
                            mode="multiple"
                            disabled={true}
                            value={record.manufacturer_subgroups[0] == undefined? []: record.manufacturer_subgroups}
                            dropdownStyle={{ zIndex: 10000, maxHeight: 400, overflow: 'auto' }}
                            style={{ width: '100%' }}
                        >
                            {menuItems}
                        </Select>
                    );
                }
            },
            {
                title: 'Action',
                dataIndex: 'id',
                width: 150,
                key: 'action',
                render: (text, record) => (
                    <div>
                        <IconButton title="Edit" aria-label="Edit" onClick={e => { this.updateCatalog(record) }}>
                            <EditIcon />
                        </IconButton>
                        <IconButton title="Delete" aria-label="Delete" onClick={e => { e.preventDefault(); this.handleDeleteEvent(record); }}>
                            <DeleteIcon />
                        </IconButton>
                    </div>
                ),
            },
        ];
        return (
            <React.Fragment>
                <FusePageCarded
                    classes={{
                        toolbar: "p-0",
                        header: "min-h-72 h-72 sm:h-136 sm:min-h-136"
                    }}
                    header={
                        <div className="flex flex-1 w-full items-center justify-between">
                            <div className="flex flex-col items-start max-w-full">
                                <div className="flex items-center max-w-full">
                                    <div className="flex flex-col min-w-0">
                                        <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                                            <Title level={4} className="text-16 sm:text-20 truncate">
                                            </Title>
                                        </FuseAnimate>
                                    </div>
                                </div>
                            </div>
                            <FuseAnimate animation="transition.slideRightIn" delay={300}>
                                <Button
                                    className="whitespace-no-wrap"
                                    variant="contained"
                                    onClick={(e) => {this.setState({manufacturer: []}); openNewUserDialog()}}
                                >
                                    ADD
                                </Button>
                            </FuseAnimate>
                        </div>

                    }
                    contentToolbar={
                        <Title level={4} style={{ marginLeft: "25px" }} className="text-16 sm:text-20 truncate" classes={{ root: "w-full h-64" }}>
                            {"Catalog"}
                        </Title>
                    }
                    content={
                        <div className="p-24">
                            <Drawer
                                anchor="right"
                                style={{zIndex: 500}}
                                {...userDialog.props}
                                variant="temporary"
                                onClose={this.closeComposeDialog}
                                classes={{ paper: classes.list }}
                            >
                                <div
                                    tabIndex={0}
                                    role="button"
                                >
                                    <Title level={3} className="pt-16" style={{ textAlign: "center" }}>
                                        {userDialog.type === "new" ? "Add" : "Edit"} Catalog
                                    </Title>
                                    <Divider />

                                    <Formsy
                                        className="m-20"
                                        onValid={this.enableButton}
                                        onInvalid={this.disableButton}
                                        ref={(form) => this.form = form}
                                    >
                                        <TextFieldFormsy
                                            className="w-full mb-24"
                                            type="text"
                                            name="name"
                                            label="Catalog Name"
                                            validations={{
                                                minLength: 1
                                            }}
                                            variant="outlined"
                                            required
                                            onChange={(e)=>{this.setState({name: e.target.value})}}
                                            value={this.state.name}

                                        />
                                        <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                                            <Button onClick={() => this.addManufacturerSubgroup("new")}>Add Manufacturer</Button>
                                            <Button onClick={this.handleViewTable} >View Manufacturers</Button>
                                        </div>
                                        <div
                                            style={{
                                                position: 'absolute',
                                                left: 0,
                                                bottom: 0,
                                                width: '100%',
                                                borderTop: '1px solid #e9e9e9',
                                                padding: '10px 16px',
                                                background: '#fff',
                                                textAlign: 'right',
                                            }}
                                        >
                                            <Button onClick={this.closeComposeDialog} style={{ marginRight: 8 }}>
                                                Cancel
                                            </Button>
                                            {userDialog.type === 'new' ? (
                                                <Button
                                                    type="submit"
                                                    onClick={this.onSubmit}
                                                    variant="contained"
                                                    color="primary"
                                                    aria-label="REGISTER"
                                                    disabled={!canSubmit}
                                                    value="legacy"
                                                >
                                                    Publish
                                                </Button>
                                            ) : (
                                                <Button
                                                    type="submit"
                                                    onClick={this.onSubmit}
                                                    variant="contained"
                                                    color="primary"
                                                    aria-label="REGISTER"
                                                    disabled={!canSubmit}
                                                    value="legacy"
                                                >
                                                    UPDATE
                                                </Button>
                                            )}
                                        </div>
                                    </Formsy>
                                </div>
                            </Drawer>
                            <ManufacturerSubgroupDrawer
                                visible={addSubgroupVisible}
                                manufacturerType={manufacturerType}
                                onClose={() => this.setState({addSubgroupVisible: false})}
                                drawerClasses = {{paper: classes.list1}}
                                selectedSubgroup = {selectedSubgroup}
                                manufacturerData={manufacturerData}
                                colorFamilyData={colorFamilyData}
                                addManufacturer={addManufacturer}
                                updateManufacturer={updateManufacturer}
                            />
                            <ManufacturerSubgroupTableModal
                                anchor = "right"
                                open = {subgroupTableVisible}
                                variant = "temporary"
                                handleCancel = {() => {this.setState({subgroupTableVisible: false})}}
                                handleOk = {() => {this.setState({subgroupTableVisible: false})}}
                                defaultValue = {subgroupData}
                                handleEdit = {(record) => this.handleEditSubgroup(record)}
                                handleDelete = {(record) => {this.handleDeleteSubgroup(record)}}
                            />
                            <Table
                                bordered
                                className="mtable"
                                columns={columns}
                                pagination={defaultPagination}
                                dataSource={data} />
                        </div>
                    }
                />
            </React.Fragment>
        )
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getCatalog: Actions.getCatalog,
        deleteCatalog: Actions.deleteCatalog,
        updateUser: Actions.updateUser,
        openNewUserDialog: Actions.openNewUserDialog,
        openEditUserDialog: Actions.openEditUserDialog,
        closeEditUserDialog: Actions.closeEditUserDialog,
        closeNewUserDialog: Actions.closeNewUserDialog,
        addUser: Actions.addUser,
        getManufacturer : Actions.getManufacturer,
        addManufacturer : Actions.addManufacturer,
        deleteManufacturer: Actions.deleteManufacturer,
        setManufacturer: Actions.setManufacturer,
        updateManufacturer: Actions.updateManufacturer
    }, dispatch);
}

function mapStateToProps({ catalog }) {
    return {
        catalog: catalog.catalog.catalogData,
        subgroup: catalog.catalog.subgroupData,
        colorAlias: catalog.catalog.colorAliasData,
        userDialog: catalog.catalog.userDialog,
        manufacturerData: catalog.catalog.manufacturerData,
        colorFamilyData: catalog.catalog.color_familys,
        doorColorsData: catalog.catalog.door_colors,
        manufacturerSubgroupData: catalog.catalog.manufacturer_subgroup_ids
    }
}

export default withReducer('catalog', reducer)(withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(Catalog))));
