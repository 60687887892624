import React, { Component } from 'react'
import { Button, Drawer, Divider} from '@material-ui/core';
import { Upload, Typography, Select, Form, Row, Col, Icon } from 'antd';
import { withStyles } from '@material-ui/core/styles';
import * as Actions from './store/actions';
import {bindActionCreators} from 'redux';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

const { Title } = Typography;
const { Option } = Select;
const FormItem = Form.Item
const formItemLayout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 12 },
};

const { Dragger } = Upload;

const styles = theme => ({
    button: {
        marginTop: "-12px",
    },
    rightIcon: {
        marginLeft: '10px',
    },
    input: {
        display: 'none',
    },
    list: {
        width: 700,
    }
});

class CabinetUploadBuildupDrawerComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            zip: []
        }
    }

    handleSubmitZip = () => {
        if(this.state.zip.length == 0){
            this.props.showMessage({
                message         : 'Not zip file',
                autoHideDuration: 2000,
                anchorOrigin    : {
                    vertical  : 'top',
                    horizontal: 'right'
                },variant : 'error'
            });
            return;
        }
        else{
            this.props.uploadBuildUp(this.state.zip);
            this.setState({zip:[]});
            this.props.onClose();
        }    
    }

    isZipFile = (fileList) => {
        if(!fileList.name.endsWith('.zip'))
        {
            return true;
        }
        return false;
    }

    normFile = e => {
        if (Array.isArray(e)) {
          return e;
        }
        return e && e.fileList;
    }

    render() {

        const props = {
            name: 'file',
            multiple: true,
        };
        const { getFieldDecorator, setFieldsValue } = this.props.form;
        const { drawerClasses, open, anchor } = this.props;

        return (
            <Drawer
                style={{zIndex: 500}}
                anchor={anchor}
                open={open}
                onClose={() => this.props.onClose()}
                classes={drawerClasses}
            >
                <div
                    tabIndex={0}
                    role="button"
                >
                    <Title level={3} className="pt-16" style={{textAlign: "center"}}>
                        Upload Buildup
                    </Title>
                    <Divider />
                    <Form layout="vertical" style={{marginTop: "20px"}}>
                    <Row gutter={8}>
                            <Col span={2} />
                            <Col span={22}>
                                <FormItem 
                                    key="zip"
                                    {...formItemLayout}
                                    label="zip file"
                                >
                                    {getFieldDecorator("zip", {
                                        initialValue: this.state.zip,
                                        rules: [{ required: true, message: 'Please upload .zip file!' }],
                                        valuePropName: 'fileList',
                                        getValueFromEvent: this.normFile,
                                    })(
                                        (   <Dragger {...props}
                                                showUploadList={true}
                                                onChange={info => {
                                                    const fData = info.fileList.filter(e => e.name.endsWith('.zip'));
                                                    this.setState({zip: fData});
                                                }}
                                                beforeUpload={fileList => {
                                                    return this.isZipFile(fileList);
                                                }}
                                            >
                                                <p className="ant-upload-drag-icon">
                                                <Icon type="inbox" />
                                                </p>
                                                <p className="ant-upload-text">.zip files to upload</p>
                                                <p className="ant-upload-hint">
                                                </p>
                                            </Dragger>
                                        )
                                    )}
                                </FormItem>
                                </Col>
                        </Row>
                        <Row>
                        <div
                            style={{
                                left: 0,
                                bottom: 0,
                                width: '100%',
                                borderTop: '1px solid #e9e9e9',
                                padding: '10px 16px',
                                background: '#fff',
                                textAlign: 'right',
                            }}
                        >
                            <Button 
                                onClick={() => {
                                    this.props.onClose();
                                }} 
                                style={{ marginRight: 8 }}>
                                Cancel
                            </Button>
                            <Button 
                                onClick={() => this.handleSubmitZip()} 
                                variant="contained" 
                                color="primary" 
                            >
                                {"ADD"}
                            </Button>
                        </div>
                        </Row>
                    </Form>
                </div>
            </Drawer>
        )
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        uploadBuildUp: Actions.uploadBuildUp,

    }, dispatch);
}

const CabinetUploadBuildupDrawer = Form.create()(CabinetUploadBuildupDrawerComponent);
export default (withStyles(styles, {withTheme: true})(CabinetUploadBuildupDrawer));