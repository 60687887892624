import axios from 'axios';
import {showMessage} from 'app/store/actions/fuse';
import { API_SERVER_URL, jsonArray, getData, uploadFiles, putFiles, deleteFiles} from 'app/main/config';
import { uploadLoading }from 'app/store/actions/fuse';

export const GET_APPLIANCE_CATEGORY = '[APPLIANCE_CATEGORY] GET APPLIANCE CATEGORY';
export function getApplianceCategory() {
    return (dispatch => {
        axios.post(`${API_SERVER_URL}/api/applianceCategory/read/appliance_category`)
        .then(response => {
            const {applianceCategory, success} = response.data;
            if(success === true) {                
                var result = getData(applianceCategory, 'parent_category_id', 0);

                dispatch({
                    type:GET_APPLIANCE_CATEGORY,
                    applianceCategory: result,
                })
            }
        }).catch(err=>{
            dispatch(showMessage({
                message         : 'Failed to load APPLIANCE CATEGORY data',
                autoHideDuration: 2000,
                anchorOrigin    : {
                    vertical  : 'top',
                    horizontal: 'right'
                },variant : 'error'
            }));
            console.log("err", err);
        });        
    })
}

export const ADD_APPLIANCE_CATEGORY = '[APPLIANCE_CATEGORY] ADD APPLIANCE CATEGORY';
export function addApplianceCategory(data) {
    var newData = {
        parent_category_id: data.parent_category_id,
        name: data.name,
        thumbnail: data.thumbnail[0].name,
    }
    const formData = new FormData();
    formData.append("file", data.thumbnail[0].originFileObj);
    
    return ((dispatch, getState) => {
        axios.post(`${API_SERVER_URL}/api/applianceCategory/insert/appliance_category`, {
            ...newData
        })
        .then(res => {
            dispatch(uploadLoading(true));
            if(process.env.REACT_APP_MODE === "production") {
                uploadFiles(data.thumbnail.filter(file => file.originFileObj !== undefined), `uploads/ApplianceCategory/${res.data.applianceCategory.guid + '_' +res.data.applianceCategory.id}/`)
                .then(thumbnail => {                        
                    const uploadData = [ 
                        {
                            fieldName: 'thumbnail',
                            type: 'single',
                            data: thumbnail
                        }
                    ];
                    axios.post(`${API_SERVER_URL}/api/base/updateUploadData`, 
                        {
                            id: res.data.applianceCategory.id,
                            tableName: "appliance_category",
                            data: uploadData
                        }
                    )
                    .then(response => {
                        const {success} = response.data;
                        dispatch(uploadLoading(false));
                        if(success === true) {
                            dispatch(showMessage({
                                message         : 'Successfully Inserted',
                                autoHideDuration: 2000,
                                anchorOrigin    : {
                                    vertical  : 'top',
                                    horizontal: 'right'
                                },variant : 'success'
                            }));
                            Promise.all([
                                dispatch({
                                    type: ADD_APPLIANCE_CATEGORY
                                })
                            ]).then(() => dispatch(getApplianceCategory()))
                        } else {
                            console.log(success);
                        }
                    })
                })
                .catch(err => {
                    console.log("failed to upload appliance category thumbnail. ", err);
                    return;
                })
            }  else {
                formData.append("guid" , res.data.applianceCategory.guid);
                formData.append("id" , res.data.applianceCategory.id);
                axios.post(`${API_SERVER_URL}/api/applianceCategory/upload`, formData)
                .then(response => {
                    const {success} = response.data;
                    dispatch(uploadLoading(false));
                    if(success === true) {
                        axios.post(`${API_SERVER_URL}/uploads/clear`)
                        .then(res => {
                            if(res.data.success === true ){
                                dispatch(showMessage({
                                    message         : 'Successfully Inserted',
                                    autoHideDuration: 2000,
                                    anchorOrigin    : {
                                        vertical  : 'top',
                                        horizontal: 'right'
                                    },variant : 'success'
                                }));
                                Promise.all([
                                    dispatch({
                                        type:ADD_APPLIANCE_CATEGORY,
                                    })
                                ]).then(() => dispatch(getApplianceCategory()))
                            }
                        })
                    }
                })    
            }
        })
        .catch(err=>{
            dispatch(showMessage({
                message         : 'Failed to add APPLIANCE CATEGORY data',
                autoHideDuration: 2000,
                anchorOrigin    : {
                    vertical  : 'top',
                    horizontal: 'right'
                },variant : 'error'
            }));
            console.log("err", err);
        });        
    })
}

export const DELETE_APPLIANCE_CATEGORY = '[APPLIANCE_CATEGORY] DELETE APPLIANCE CATEGORY';
export function deleteApplianceCategory(record) {
    return ((dispatch) => {
        var p1 = new Promise((resolve, reject) => {
            dispatch(uploadLoading(true));
            if(process.env.REACT_APP_MODE === "production" ) {
                const data = [];
                data.push(record.thumbnail);
                deleteFiles(data).then(res => {
                    resolve("success");
                })
                .catch(err => {
                    console.log(err);
                    reject(err);
                })
            } else {
                resolve("success");
            }
        })
        p1.then(p1Value => {
            axios.post(`${API_SERVER_URL}/api/applianceCategory/remove/appliance_category`, {
                id: record.id
            })
            .then(response => {
                const {success} = response.data;
                dispatch(uploadLoading(false));
                if(success === true) {
                    dispatch(showMessage({
                        message         : 'Successfully Deleted',
                        autoHideDuration: 2000,
                        anchorOrigin    : {
                            vertical  : 'top',
                            horizontal: 'right'
                        },variant : 'success'
                    }));
                    Promise.all([
                        dispatch({
                            type:DELETE_APPLIANCE_CATEGORY,
                        })
                    ]).then(() => dispatch(getApplianceCategory()))
                }
            }).catch(err=>{
                dispatch(uploadLoading(false));
                dispatch(showMessage({
                    message         : 'Failed to delete APPLIANCE CATEGORY data',
                    autoHideDuration: 2000,
                    anchorOrigin    : {
                        vertical  : 'top',
                        horizontal: 'right'
                    },variant : 'error'
                }));
                console.log("err", err);
            });            
        })
        .catch (err => {
            dispatch(uploadLoading(false));
            dispatch(showMessage({
                message: 'AWS S3 DELETE APPLIANCE_CATEGORY ERROR',
                autoHideDuration: 4000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                variant: 'error'
            }));
            return;
        })
    })
}

export const UPDATE_APPLIANCE_CATEGORY = '[APPLIANCE_CATEGORY] UPDATE APPLIANCE CATEGORY';
export function updateApplianceCategory(data, id, guid) {
    var updateData = {
        id                      : id,
        name                    : data.name,
        thumbnail               : jsonArray([data.thumbnail[0]]),
        parent_category_id      : data.parent_category_id,
    }

    return ((dispatch, getState) => {
        axios.post(`${API_SERVER_URL}/api/applianceCategory/update/appliance_category`, {
            ...updateData
        }).then(res => {
            dispatch(uploadLoading(true));
            if(process.env.REACT_APP_MODE === "production") {
                putFiles(data.thumbnail.filter(file => file.originFileObj !== undefined), `uploads/ApplianceCategory/${guid + '_' + id}/`).then(thumbnail => {                        
                    const uploadData = [{
                        fieldName: 'thumbnail',
                        type: 'single',
                        data: thumbnail
                    }];

                    axios.post(`${API_SERVER_URL}/api/base/updateUploadData`, 
                        {
                            id: id,
                            tableName: "appliance_category",
                            data: uploadData
                        }
                    )
                    .then(response => {
                        const {success} = response.data;
                        dispatch(uploadLoading(false));

                        if(success === true) {
                            dispatch(showMessage({
                                message         : 'Successfully Updated',
                                autoHideDuration: 2000,
                                anchorOrigin    : {
                                    vertical  : 'top',
                                    horizontal: 'right'
                                },variant : 'success'
                            }));
                            Promise.all([
                                dispatch({
                                    type: UPDATE_APPLIANCE_CATEGORY
                                })
                            ]).then(() => dispatch(getApplianceCategory()))
                        } else {
                            console.log(success);
                        }
                    })
                })
                .catch(err => {
                    console.log("failed to put appliance category thumbnail. ", err);
                    return;
                })
            } else {
                if(data.thumbnail[0].originFileObj !== undefined) {
                    const formData = new FormData();
                    formData.append("file", data.thumbnail[0].originFileObj);
                    formData.append("guid" , guid);
                    formData.append("id" , id);
                    axios.post(`${API_SERVER_URL}/api/applianceCategory/upload`, formData)
                    .then(response => {
                        const {success} = response.data;
                        dispatch(uploadLoading(false));

                        if(success === true) {
                            axios.post(`${API_SERVER_URL}/uploads/clear`)
                            .then(res => {
                                if(res.data.success === true ){
                                    dispatch(showMessage({
                                        message         : 'Successfully Updated',
                                        autoHideDuration: 2000,
                                        anchorOrigin    : {
                                            vertical  : 'top',
                                            horizontal: 'right'
                                        },variant : 'success'
                                    }));
                                    Promise.all([
                                        dispatch({
                                            type:UPDATE_APPLIANCE_CATEGORY,
                                        })
                                    ]).then(() => dispatch(getApplianceCategory()))
                                }
                            })
                        }
                    })
                }else{
                    dispatch(uploadLoading(false));
                    dispatch(showMessage({
                        message         : 'Successfully Updated',
                        autoHideDuration: 2000,
                        anchorOrigin    : {
                            vertical  : 'top',
                            horizontal: 'right'
                        },variant : 'success'
                    }));
                    Promise.all([
                        dispatch({
                            type:UPDATE_APPLIANCE_CATEGORY,
                        })
                    ]).then(() => dispatch(getApplianceCategory()))
                }
            }
        })
        .catch(err=>{
            dispatch(showMessage({
                message         : 'Failed to update APPLIANCE CATEGORY data',
                autoHideDuration: 2000,
                anchorOrigin    : {
                    vertical  : 'top',
                    horizontal: 'right'
                },variant : 'error'
            }));
            console.log("err", err);
        });        
      
    })
}

export const SET_APPLIANCE_CATEGORY_SORT_ORDER = '[APPLAINCE_CATEGORY] SET SORT ORDER';
export function setSortOrder(data) {
    return ((dispatch) => {
        dispatch(uploadLoading(true));
        axios.post(`${API_SERVER_URL}/api/base/setSortOrder`, {
            id: data.id,
            sort_order: data.sort_order,
            table_name: 'appliance_category'
        })
        .then(response => {
            const {success} = response.data;
            if(success === true) {
                dispatch(uploadLoading(false));
                Promise.all([
                    dispatch({
                        type:SET_APPLIANCE_CATEGORY_SORT_ORDER,
                    })  
                ]).then(() => dispatch(getApplianceCategory()))
            } else {
                dispatch(uploadLoading(false));
            }
        })
        .catch(err => {
            dispatch(uploadLoading(false));
            dispatch(showMessage({
                message         : 'Failed to set sort order',
                autoHideDuration: 2000,
                anchorOrigin    : {
                    vertical  : 'top',
                    horizontal: 'right'
                },variant : 'error'
            }));
            console.log("failed to set sort order in appliance category.", err);
        })
    })
}