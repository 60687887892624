import * as Actions from '../actions';

const initialState = {
    cabinetCategory: [],
};

const doorCategoryReducer = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.GET_CABINET_CATEGORY:
        {
            return {
                ...state,
                ...action,
            }   
        }
        default:
        {
            return state;
        }
    }
};

export default doorCategoryReducer;
