import React, {Component} from 'react';
import * as Actions from './store/actions';
import reducer from './store/reducers';
import {withRouter} from 'react-router-dom';
import withReducer from 'app/store/withReducer';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {FusePageCarded, FuseAnimate} from '@fuse';
import {IconButton, Button, Drawer, Divider} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { withStyles } from '@material-ui/core/styles';
import {Table, Typography, Input, Upload, Form, Spin, Modal, Row, Col,Tooltip} from 'antd';

const { Title } = Typography;
const { confirm } = Modal;
const FormItem = Form.Item;
const formItemLayout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 12 },
};
const defaultPagination = {
    pageSizeOptions: ['10', '20', '50', '70'],
    showSizeChanger: true,
    size: 'small',
    position: 'top',
}
const styles = theme => ({
    button: {
        marginTop: "-12px",
    },
    input: {
        display: 'none',
    },
    list: {
        width: 500,
    },
});

class Backsplash extends Component {
   
    constructor(props) {
        super(props);
        this.state = {
            name      : '',
            thumbnail : [],
            texture   : [],
            visible   : false,
            type      : "new",
            id        : null,
        };
        props.getBacksplash();
       
    }

    multiUploadFileArray = (dataArray, record) => {
        const results = dataArray.map((obj,index) => ({
            uid     : index,
            name    : obj.split('/').pop(),
            status  : 'done',
            url     : obj,
        }));

        return results;
    }

    toggleDrawer = (type, record, open) => () => {
        if(type === "new") {
            this.setState({
                id        : null,
                name      : '',
                thumbnail : [],
                texture   : [],
                type      : type,
            })
        }
        else if(type === "edit") {
        
            this.setState({
                name: record.name,
                type            : type,
                id              : record.id,
                guid            : record.guid,
                thumbnail: [{
                    uid     : 1,
                    name    : record.thumbnail.split('/').pop(),
                    status  : 'done',
                    url     : record.thumbnail,
                }],
                texture: [{
                    uid     : 1,
                    name    : record.texture.split('/').pop(),
                    status  : 'done',
                    url     : record.texture,
                }],
            })
        }
        this.setState({
          visible: open,
        });
    };

    handleSubmit = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
              const {type, id} = this.state;
              this.setState({visible: false});
                if(type === "new") {
                    this.props.addBacksplash({...this.state, thumbnail: values.thumbnail, texture: values.texture});
                }
                if(type === "edit" && id !== null) {
                    this.props.updateBacksplash({...this.state, thumbnail: values.thumbnail, texture: values.texture}, id, this.state.guid);
                }
                
            }
            
          });
    }

    handleDeleteEvent = (record) => {
        const {deleteBacksplash} = this.props;
        confirm({
            title: 'Do you want to delete this Item?',
            onOk() {
                deleteBacksplash(record)
            },
            onCancel() {},
        });
    }

    normFile = e => {
        if (Array.isArray(e)) {
          return e;
        }
        return e && e.fileList;
    }

    moveAction = (type, record) => () => {
        const {backsplash} = this.props;
        const index = backsplash.findIndex( el => el.id === record.id);
        let swapIndex = -1; 
        if (type === "up" && index !== 0 ) {
            swapIndex = index - 1;
        }
        else if (type === "down" && index !== backsplash.length - 1) {
            swapIndex = index + 1;
        }
        if(swapIndex !== -1) {
            this.props.setSortOrder({id: backsplash[index].id, sort_order: backsplash[swapIndex].sort_order});
            this.props.setSortOrder({id: backsplash[swapIndex].id, sort_order: backsplash[index].sort_order});
        }
    }
      
    render()
    {
        const { classes, backsplash, isLoading} = this.props;
        const { thumbnail, texture , name, visible, type} = this.state;
        const { getFieldDecorator, setFieldsValue } = this.props.form;

        const uploadButton = (
            <div>
              <AddIcon />
              <div className="ant-upload-text">
                {type==="new" ? "Upload" : "Change & Upload"}
            </div>
            </div>
        );
        const columns = [
            {
                key: 'name',
                title: 'Name',
                dataIndex: 'name',
                width: 150,
            },
            {
                key: 'texture',
                title: 'Texture',
                dataIndex: 'texture',
                colSpan: 2,
                width: 450,
                render: (text, record) => (
                    <Tooltip placement="topLeft" title={text}>
                        <span>{text.slice(0,40) + '...'}</span>
                    </Tooltip>
                )
            },
            {
                key: 'preview_t',
                dataIndex: 'texture',
                colSpan: 0,
                width: 150,
                render: (text, record) => (
                    <Row className="list__thumbnail">
                        <img src={text} alt="" />
                    </Row>
                ),
            },
            {
                key: 'thumbnail',
                title: 'Thumbnail',
                dataIndex: 'thumbnail',
                colSpan: 2,
                width: 450,
                render: (text, record) => (
                    <Tooltip placement="topLeft" title={text}>
                        <span>{text.slice(0,40) + '...'}</span>
                    </Tooltip>
                )
            },
            {
                key: 'preview',
                dataIndex: 'thumbnail',
                colSpan: 0,
                width: 150,
                render: (text, record) => (
                    <Row className="list__thumbnail">
                        <img src={text} alt="" />
                    </Row>
                ),
            },
            {
                key: 'action',
                title: 'Action',
                dataIndex: 'id',
                width: 250,
                fixed: 'right',
                render: (text, record) => (
                    <Row>
                        <IconButton className={classes.button} aria-label="ArrowUp" onClick={this.moveAction("up", record)}>
                            <ArrowUpwardIcon />
                        </IconButton>
                        <IconButton className={classes.button} aria-label="ArrowDown" onClick={this.moveAction("down", record)}>
                            <ArrowDownwardIcon />
                        </IconButton>
                        <IconButton className={classes.button} aria-label="Delete" onClick={this.toggleDrawer("edit", record, true)}>
                            <EditIcon />
                        </IconButton>
                        <IconButton className={classes.button} aria-label="Edit" onClick={e => {e.preventDefault(); this.handleDeleteEvent(record);}}>
                            <DeleteIcon />
                        </IconButton>
                    </Row>
                ),
            },
        ];
     
        return (
            <Spin tip="Loading..." spinning={isLoading}>
                <FusePageCarded
                    classes={{
                        toolbar: "p-0",
                        header : "min-h-72 h-72 sm:h-136 sm:min-h-136"
                    }}
                    header={
                        <div className="flex flex-1 w-full items-center justify-between">
                            <div className="flex flex-col items-start max-w-full">
                                <div className="flex items-center max-w-full">
                                    <div className="flex flex-col min-w-0">
                                        <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                                            <Title level={4} className="text-16 sm:text-20 truncate">
                                            </Title>
                                        </FuseAnimate>
                                    </div>
                                </div>
                            </div>
                            <FuseAnimate animation="transition.slideRightIn" delay={300}>
                                <Button
                                    className="whitespace-no-wrap"
                                    variant="contained"
                                    onClick={this.toggleDrawer("new", null, true)}
                                >
                                    Add
                                </Button>
                            </FuseAnimate>
                        </div>
    
                    }
                    contentToolbar={
                        <Title level={4} style={{marginLeft: "25px"}} className="text-16 sm:text-20 truncate" classes={{root: "w-full h-64"}}>
                            {"Backsplash"}
                        </Title>
                    }
                    content={
                        <div className="p-24">
                            <Row>
                                <Drawer
                                    anchor="right"
                                    open={visible}
                                    variant="temporary"
                                    onClose={this.toggleDrawer(null, null, false)}
                                    classes={{paper: classes.list}}
                                    onRendered={() => {
                                        var data = {
                                            name      : this.state.name,
                                            thumbnail : this.state.thumbnail,
                                            texture : this.state.texture,
                                        }
                                        setFieldsValue({...data})}}
                                >
                                    <div
                                        tabIndex={0}
                                        role="button"
                                    >
                                        <Title level={3} className="pt-16" style={{textAlign: "center"}}>
                                            { type === "new" ? "Add" : "Edit"} Backsplash
                                        </Title>
                                        <Divider />
                                        <Form layout="vertical" style={{marginTop: "20px"}}>
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                    <FormItem 
                                                        key="name"
                                                        {...formItemLayout}
                                                        label="Backsplash Name"
                                                    >
                                                        {getFieldDecorator("name", {
                                                            initialValue: name,
                                                            rules: [ {
                                                                required: true, message: 'Enter Backsplash.',
                                                            }],
                                                        })(
                                                            (<Input
                                                                placeholder="Enter Backsplash Name"
                                                                onChange={(e)=> {
                                                                    this.setState({name: e.target.value});
                                                                }}
                                                            />)
                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                    <FormItem 
                                                        key="Texture"
                                                        {...formItemLayout}
                                                        label="Texture"
                                                    >
                                                        {getFieldDecorator("texture", {
                                                            initialValue: texture,
                                                            valuePropName: 'fileList',
                                                            rules: [{ required: true, message: 'Please upload Texture!' }],
                                                            getValueFromEvent: this.normFile,
                                                        })(
                                                            ( <Upload
                                                                listType="picture-card"
                                                                className="upload-list-inline"
                                                                onRemove={file => {
                                                                    this.setState({
                                                                        texture: [],
                                                                    });
                                                                }}
                                                                beforeUpload={(file, fileList) => {
                                                                    this.setState({
                                                                        texture: fileList,
                                                                    });
                                                                    return false;
                                                                }}
                                                                >
                                                                {texture.length > 0 ? null : uploadButton}
                                                            </Upload>)
                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                    <FormItem 
                                                        key="Thumbnail"
                                                        {...formItemLayout}
                                                        label="Thumbnail"
                                                    >
                                                        {getFieldDecorator("thumbnail", {
                                                            initialValue: thumbnail,
                                                            valuePropName: 'fileList',
                                                            rules: [{ required: true, message: 'Please upload Thumbnail!' }],
                                                            getValueFromEvent: this.normFile,
                                                        })(
                                                            ( <Upload
                                                                listType="picture-card"
                                                                className="upload-list-inline"
                                                                onRemove={file => {
                                                                    this.setState({
                                                                        thumbnail: [],
                                                                    });
                                                                }}
                                                                beforeUpload={(file, fileList) => {
                                                                    this.setState({
                                                                        thumbnail: fileList,
                                                                    });
                                                                    return false;
                                                                }}
                                                                >
                                                                {thumbnail.length > 0 ? null : uploadButton}
                                                            </Upload>)
                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                        </Form>  
                                        <div
                                            style={{
                                                position: 'absolute',
                                                left: 0,
                                                bottom: 0,
                                                width: '100%',
                                                borderTop: '1px solid #e9e9e9',
                                                padding: '10px 16px',
                                                background: '#fff',
                                                textAlign: 'right',
                                            }}
                                        >
                                            <Button onClick={this.toggleDrawer(null, null, false)} style={{ marginRight: 8 }}>
                                                Cancel
                                            </Button>
                                            <Button 
                                                onClick={this.handleSubmit} 
                                                variant="contained" 
                                                color="primary" 
                                                // disabled={!this.canBeSubmitted()}
                                            >
                                                {type === "new" ? "ADD" : "UPDATE"}
                                            </Button>
                                        </div>
                                    </div>
                                </Drawer>
                                <Table 
                                    bordered
                                    rowKey="id"
                                    className="mtable"
                                    pagination={defaultPagination}
                                    columns={columns} 
                                    dataSource={backsplash}
                                    scroll={{x: 1500, y: 500}}
                                />
                            </Row>
                        </div>
                    }
                />
            </Spin>
        )
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        getBacksplash: Actions.getBacksplash,
        addBacksplash: Actions.addBacksplash,
        setSortOrder: Actions.setSortOrder,
        deleteBacksplash: Actions.deleteBacksplash,
        updateBacksplash: Actions.updateBacksplash,
    }, dispatch);
}

function mapStateToProps({backsplash, fuse})
{
    return {
        backsplash: backsplash.backsplash.backsplash,
        isLoading:fuse.loading.isLoading
    }
}
const BacksplashComponent = Form.create()(Backsplash);

export default withReducer('backsplash', reducer)(withStyles(styles, {withTheme: true})(withRouter(connect(mapStateToProps,mapDispatchToProps)(BacksplashComponent))));