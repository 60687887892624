/**
 * Authorization Roles
 */
const authRoles = {
    superDesigner:['superdesigner', 'superadmin', 'admin'],
    onlyProject:['customer','designer','superdesigner', 'superadmin', 'admin'],
    designer:['superadmin', 'admin'],
    // allUsers:['designer', 'superadmin', 'superdesigner', 'customer']
};

export default authRoles;
