import axios from 'axios';
import {showMessage} from 'app/store/actions/fuse';
import { API_SERVER_URL, jsonArray, uploadFiles, putFiles, deleteFiles } from 'app/main/config';
import { uploadLoading }from 'app/store/actions/fuse';

export const GET_COUNTER_TOP = '[COUNTER_TOP] GET COUNTER TOP';
export function getCounterTop() {
    return (dispatch => {
        axios.post(`${API_SERVER_URL}/api/counterTop/read/counter_top`)
        .then(response => {
            const {counterTop} = response.data;
                dispatch({
                    type:GET_COUNTER_TOP,
                    counterTop: counterTop,
                })
        }).catch(err=>{
            dispatch(showMessage({
                message         : 'Failed to load COUNTER TOP data',
                autoHideDuration: 2000,
                anchorOrigin    : {
                    vertical  : 'top',
                    horizontal: 'right'
                },variant : 'error'
            }));
            console.log("err", err);
        });    
    })
}

export const ADD_COUNTER_TOP = '[COUNTER_TOP] ADD COUNTER TOP';
export function addCounterTop(data) {
    var newData = {
        name: data.name,
        thumbnail: data.thumbnail[0].name,
        texture : data.texture[0].name
    }
   
    const formData = new FormData();
    formData.append("thumbnail",data.thumbnail[0].originFileObj);
    formData.append("texture", data.texture[0].originFileObj);
    
    return ((dispatch) => {
        axios.post(`${API_SERVER_URL}/api/counterTop/insert/counter_top`, {
            ...newData
        })
        .then(res => {
            dispatch(uploadLoading(true));
            if(process.env.REACT_APP_MODE === "production") {
                uploadFiles(data.texture.filter(file => file.originFileObj !== undefined), `uploads/CountTop/${res.data.data.guid + '_' + res.data.data.id}/texture/`).then(texture => {
                    uploadFiles(data.thumbnail.filter(file => file.originFileObj !== undefined), `uploads/CountTop/${res.data.data.guid + '_' + res.data.data.id}/thumbnail/`).then(thumbnail => {                        
                        const uploadData = [
                            {
                                fieldName: 'texture',
                                type: 'single',
                                data: texture
                            },
                            {
                                fieldName: 'thumbnail',
                                type: 'single',
                                data: thumbnail
                            }
                        ];
                        axios.post(`${API_SERVER_URL}/api/base/updateUploadData`, 
                            {
                                id: res.data.data.id,
                                tableName: "counter_top",
                                data: uploadData
                            }
                        )
                        .then(response => {
                            const {success} = response.data;
                            dispatch(uploadLoading(false));
                            if(success === true) {
                                dispatch(showMessage({
                                    message         : 'Successfully Inserted',
                                    autoHideDuration: 2000,
                                    anchorOrigin    : {
                                        vertical  : 'top',
                                        horizontal: 'right'
                                    },variant : 'success'
                                }));
                                Promise.all([
                                    dispatch({
                                        type: ADD_COUNTER_TOP
                                    })
                                ]).then(() => dispatch(getCounterTop()))
                            } else {
                                console.log(success);
                            }
                        })
                    })
                    .catch(err => {
                        console.log("failed to upload counttop thumbnail. ", err);
                        return;
                    })
                })
                .catch(err => {
                    console.log("failed to upload counttop texture. ", err);
                    return;
                })
            } else {
                formData.append("guid" , res.data.data.guid);
                formData.append("id" , res.data.data.id);
                axios.post(`${API_SERVER_URL}/api/counterTop/upload`, formData)
                .then(response => {
                    const {success} = response.data;
                    dispatch(uploadLoading(false));
                    if(success === true) {
                        axios.post(`${API_SERVER_URL}/uploads/clear`)
                        .then(res => {
                            if(res.data.success === true ){
                                dispatch(showMessage({
                                    message         : 'Successfully Inserted',
                                    autoHideDuration: 2000,
                                    anchorOrigin    : {
                                        vertical  : 'top',
                                        horizontal: 'right'
                                    },variant : 'success'
                                }));
                                Promise.all([
                                    dispatch({
                                        type: ADD_COUNTER_TOP
                                    })
                                ]).then(() => dispatch(getCounterTop()))
                            }
                        })
                    }
                }).catch(err => {
                    dispatch(showMessage({
                        message         : 'Failed to add COUNTER TOP data',
                        autoHideDuration: 2000,
                        anchorOrigin    : {
                            vertical  : 'top',
                            horizontal: 'right'
                        },variant : 'error'
                    }));
        
                    console.log("err", err);
                });    
            }
        })
        .catch(err => {
            dispatch(showMessage({
                message         : 'Failed to upload image',
                autoHideDuration: 2000,
                anchorOrigin    : {
                    vertical  : 'top',
                    horizontal: 'right'
                },variant : 'error'
            }));

            console.log("err", err);
        });
    })
}

export const DELETE_COUNTER_TOP = '[COUNTER_TOP] DELETE COUNTER TOP';
export function deleteCounterTop(record) {
    return ((dispatch) => {
        var p1 = new Promise((resolve, reject) => {
            dispatch(uploadLoading(true));
            if(process.env.REACT_APP_MODE === "production" ) {
                const data = [];
                data.push(record.thumbnail);
                data.push(record.texture);
                deleteFiles(data).then(res => {
                    resolve("success");
                })
                .catch(err => {
                    console.log(err);
                    reject(err);
                })
            } else {
                resolve("success");
            }
        })
        p1.then(p1Value => {
            axios.post(`${API_SERVER_URL}/api/CounterTop/remove/counter_top`, {
                id: record.id
            })
            .then(response => {
                const {success} = response.data;
                dispatch(uploadLoading(false));
                if(success === true) {
                    dispatch(showMessage({
                        message         : 'Successfully Deleted',
                        autoHideDuration: 2000,
                        anchorOrigin    : {
                            vertical  : 'top',
                            horizontal: 'right'
                        },variant : 'success'
                    }));
                
                    Promise.all([
                        dispatch({
                            type: DELETE_COUNTER_TOP
                        })
                    ]).then(() => dispatch(getCounterTop()))
                }
            }).catch(err => {
                dispatch(uploadLoading(false));
                dispatch(showMessage({
                    message         : 'Failed to delete COUNTER TOP data',
                    autoHideDuration: 4000,
                    anchorOrigin    : {
                        vertical  : 'top',
                        horizontal: 'right'
                    },variant : 'error'
                }));

                console.log("err", err);
            });
        })
        .catch (err => {
            dispatch(uploadLoading(false));
            dispatch(showMessage({
                message         : 'AWS S3 DELETE COUNT_TOP ERROR',
                autoHideDuration: 4000,
                anchorOrigin    : {
                    vertical  : 'top',
                    horizontal: 'right'
                },variant : 'error'
            }));
            return;
        })
    })
}

export const UPDATE_COUNTER_TOP = '[COUNTER_TOP] UPDATE COUNTER TOP';
export function updateCounterTop(data, id, guid) {
   
    var updateData = {
        id        : id,
        name      : data.name,
        thumbnail :  jsonArray([data.thumbnail[0]]),
        texture :  jsonArray([data.texture[0]]), 
    }

    return ((dispatch) => {
        axios.post(`${API_SERVER_URL}/api/counterTop/update/counter_top`, {
            ...updateData
        })
        .then(res => {
            dispatch(uploadLoading(true));
            if(process.env.REACT_APP_MODE === "production") {
                putFiles(data.texture.filter(file => file.originFileObj !== undefined), `uploads/CountTop/${guid + '_' + id}/texture/`).then(texture => {
                    putFiles(data.thumbnail.filter(file => file.originFileObj !== undefined), `uploads/CountTop/${guid + '_' + id}/thumbnail/`).then(thumbnail => {                        
                        const uploadData = [
                            {
                                fieldName: 'texture',
                                type: 'single',
                                data: texture
                            },
                            {
                                fieldName: 'thumbnail',
                                type: 'single',
                                data: thumbnail
                            }
                        ];

                        axios.post(`${API_SERVER_URL}/api/base/updateUploadData`, 
                            {
                                id: id,
                                tableName: "counter_top",
                                data: uploadData
                            }
                        )
                        .then(response => {
                            const {success} = response.data;
                            dispatch(uploadLoading(false));
                            if(success === true) {
                                dispatch(showMessage({
                                    message         : 'Successfully Updated',
                                    autoHideDuration: 2000,
                                    anchorOrigin    : {
                                        vertical  : 'top',
                                        horizontal: 'right'
                                    },variant : 'success'
                                }));
                                Promise.all([
                                    dispatch({
                                        type: UPDATE_COUNTER_TOP
                                    })
                                ]).then(() => dispatch(getCounterTop()))
                            } else {
                                console.log(success);
                            }
                        })
                    })
                    .catch(err => {
                        console.log("failed to put counttop thumbnail. ", err);
                        return;
                    })
                })
                .catch(err => {
                    console.log("failed to put counttop texture. ", err);
                    return;
                })
            }
            else {
                if(data.thumbnail[0].originFileObj !== undefined || data.texture[0].originFileObj !== undefined) {
                    const formData = new FormData();
                    formData.append("guid" , guid);
                    formData.append("id" , id);
                    if(data.thumbnail[0].originFileObj !== undefined) {
                        formData.append("thumbnail", data.thumbnail[0].originFileObj);
                    }
                    if(data.texture[0].originFileObj !== undefined) {
                        formData.append("texture", data.texture[0].originFileObj);
                    }
                    axios.post(`${API_SERVER_URL}/api/counterTop/upload`, formData)
                    .then(response => {
                        const {success} = response.data;
                        dispatch(uploadLoading(false));
                        if(success === true) {
                            axios.post(`${API_SERVER_URL}/uploads/clear`)
                            .then(res => {
                                if(res.data.success === true ){
                                    dispatch(showMessage({
                                        message         : 'Successfully Updated',
                                        autoHideDuration: 2000,
                                        anchorOrigin    : {
                                            vertical  : 'top',
                                            horizontal: 'right'
                                        },variant : 'success'
                                    }));
                                    Promise.all([
                                        dispatch({
                                            type: UPDATE_COUNTER_TOP
                                        })
                                    ]).then(() => dispatch(getCounterTop()))
                                }
                            })
                        }
                    }).catch(err => {
                        dispatch(showMessage({
                            message         : 'Failed to update COUNTER TOP data',
                            autoHideDuration: 2000,
                            anchorOrigin    : {
                                vertical  : 'top',
                                horizontal: 'right'
                            },variant : 'error'
                        }));
            
                        console.log("err", err);
                    });
                }else{
                    dispatch(uploadLoading(false));
                    dispatch(showMessage({
                        message         : 'Successfully Updated',
                        autoHideDuration: 2000,
                        anchorOrigin    : {
                            vertical  : 'top',
                            horizontal: 'right'
                        },variant : 'success'
                    }));
                    Promise.all([
                        dispatch({
                            type: UPDATE_COUNTER_TOP
                        })
                    ]).then(() => dispatch(getCounterTop()))
                }        
            }
        })
        .catch(err => {
            dispatch(showMessage({
                message         : 'Failed to upload image',
                autoHideDuration: 2000,
                anchorOrigin    : {
                    vertical  : 'top',
                    horizontal: 'right'
                },variant : 'error'
            }));

            console.log("err", err);
        });    
    })
}
export const SET_COUNTER_TOP_SORT_ORDER = '[COUNTER_TOP] SET SORT ORDER';
export function setSortOrder(data) {
    return ((dispatch) => {
        dispatch(uploadLoading(true));
        axios.post(`${API_SERVER_URL}/api/base/setSortOrder`, {
            id: data.id,
            sort_order: data.sort_order,
            table_name: 'counter_top'
        })
        .then(response => {
            const {success} = response.data;
            if(success === true) {
                dispatch(uploadLoading(false));
                Promise.all([
                    dispatch({
                        type:SET_COUNTER_TOP_SORT_ORDER,
                    })  
                ]).then(() => dispatch(getCounterTop()))
            } else {
                dispatch(uploadLoading(false));
            }
        })
        .catch(err => {
            dispatch(uploadLoading(false));
            dispatch(showMessage({
                message         : 'Failed to set sort order',
                autoHideDuration: 2000,
                anchorOrigin    : {
                    vertical  : 'top',
                    horizontal: 'right'
                },variant : 'error'
            }));
            console.log("failed to set sort order in door style.", err);
        })
    })
}