import axios from 'axios';
import {showMessage} from 'app/store/actions/fuse';
import { API_SERVER_URL, jsonArray, uploadFiles, putFiles, deleteFiles, getDoorStyleTreeData, } from 'app/main/config';
import { uploadLoading }from 'app/store/actions/fuse';

export const SET_PUBLISH_FLAG = '[DOOR_COLOR] SET PUBLISH FLAG';
export function setPublishedFlag(id, value) {
    return (dispatch) => {
        dispatch(uploadLoading(true));
        axios.post(`${API_SERVER_URL}/api/doorColor/published`, {
            id: id,
            value: value === true ? 1 : 0,
        })
        .then(response => {
            dispatch(uploadLoading(false));
            const {success} = response.data;
            if(success === true) {
                dispatch({
                    type: SET_PUBLISH_FLAG
                })
            }
        })
        .catch(err => {
            dispatch(uploadLoading(false));
            console.log("err", err);
        })
    }
}

export const SET_ACTIVE_FLAG = '[DOOR_COLOR] SET ACTIVE FLAG';
export function setActiveFlag(id, value) {
    return (dispatch) => {
        dispatch(uploadLoading(true));
        axios.post(`${API_SERVER_URL}/api/doorColor/active`, {
            id: id,
            value: value === true ? 1 : 0,
        })
        .then(response => {
            dispatch(uploadLoading(false));
            const {success} = response.data;
            if(success === true) {
                dispatch({
                    type: SET_ACTIVE_FLAG
                })
            }
        })
        .catch(err => {
            dispatch(uploadLoading(false));
            console.log("err", err);
        })
    }
}

export function getConstData() {
    return (dispatch => {
        dispatch(getDoorColor());
        dispatch(getDoorStyle());
        dispatch(getCollection());
        dispatch(getDoorColorFamily());
    })
}

export const GET_COLLECTION = '[DOOR_COLOR] GET COLLECTION';
export function getCollection() {
    return (dispatch => {
        axios.post(`${API_SERVER_URL}/api/collection/read/collection`)
        .then(response => {
            const {collectionData, success} = response.data;
            if(success === true) {
                dispatch({
                    type:GET_COLLECTION,
                    collectionData: collectionData,
                });
            }
        })    
    });
}

export const GET_DOOR_COLOR = '[DOOR_COLOR] GET DOOR COLOR';
export function getDoorColor() {
    return (dispatch => {
        axios.post(`${API_SERVER_URL}/api/doorColor/read/door_color`)
        .then(response => {
            const {doorColor, success} = response.data;
            if(success === true) {     
                dispatch({
                    type:GET_DOOR_COLOR,
                    doorColor,
                })
            }
        })
        .catch(err => {
            dispatch(showMessage({
                message         : 'Failed to load DOOR COLOR data',
                autoHideDuration: 2000,
                anchorOrigin    : {
                    vertical  : 'top',
                    horizontal: 'right'
                },variant : 'error'
            }));
            console.log("err", err);
        })    
    })
}

export const GET_DOOR_COLOR_FAMILY = '[DOOR_COLOR_SET] GET DOOR COLOR SET';
export function getDoorColorFamily() {
    return (dispatch => {
        axios.post(`${API_SERVER_URL}/api/doorcolorfamily/read/doorcolorfamily`)
        .then(response => {
            const {doorcolorfamilyData, success} = response.data;
            if(success === true) {     
                dispatch({
                    type:GET_DOOR_COLOR_FAMILY,
                    doorColorFamilyData: doorcolorfamilyData,
                });
            }
        })
        .catch(err => {
            dispatch(showMessage({
                message         : 'Failed to load DOOR COLOR FAMILY data',
                autoHideDuration: 2000,
                anchorOrigin    : {
                    vertical  : 'top',
                    horizontal: 'right'
                },variant : 'error'
            }));
            console.log("err", err);
        })    
    })
}

export const GET_DOOR_STYLE = '[DOOR_COLOR] GET DOOR STYLE';
export function getDoorStyle() {
    return (dispatch => {
        axios.post(`${API_SERVER_URL}/api/doorStyle/read/door_style`)
        .then(response => {
            const {doorStyle} = response.data;  
            axios.post(`${API_SERVER_URL}/api/doorCategory/read/door_category`)
            .then(response => {
                const {doorCategory, success} = response.data;
                if(success === true) {
                    var result = getDoorStyleTreeData(doorCategory, doorStyle, 0, "");
                    
                    dispatch({
                        type:GET_DOOR_STYLE,
                        doorStyle: result,
                    })
                }
            })
            .catch(err=>{
                console.log(err);
                dispatch(showMessage({
                    message         : 'Failed to load DOOR CATEGORY data',
                    autoHideDuration: 2000,
                    anchorOrigin    : {
                        vertical  : 'top',
                        horizontal: 'right'
                    },variant : 'error'
                }));
            });   
        }).catch(err=>{
            dispatch(showMessage({
                message         : 'Failed to load DOOR STYLE data',
                autoHideDuration: 2000,
                anchorOrigin    : {
                    vertical  : 'top',
                    horizontal: 'right'
                },variant : 'error'
            }));
        });        
    })
}

export const ADD_DOOR_COLOR = '[DOOR_COLOR] ADD DOOR COLOR';
export function addDoorColor(data) {
    var getArray = (data) => {
        var items = '';
        data.forEach(( fElement, index) => {
            if(index === 0)
                items += fElement;
            else
                items += ',' + fElement;
        });
        return items;
    }
    var newData = {
        name            : data.name,
        door_style_id   : getArray(data.door_style_id),
        collection_id   : data.collection_id,
        thumbnail       : data.thumbnail[0].name,
        texture         : data.texture.length === 0 ?  "" : data.texture[0].name,
        color           : data.color,
        glossness       : data.glossness,
        sku             : data.sku,
        color_family_id : getArray(data.color_family_id),
    }
    const formData = new FormData();
    formData.append("thumbnail", data.thumbnail[0].originFileObj);
    formData.append("texture", data.texture.length === 0 ? null : data.texture[0].originFileObj);
    
    return ((dispatch, getState) => {
        axios.post(`${API_SERVER_URL}/api/doorColor/insert/door_color`, {
            ...newData
        })
        .then(res => {
            dispatch(uploadLoading(true));
            if(process.env.REACT_APP_MODE === "production") {
                uploadFiles(data.thumbnail.filter(file => file.originFileObj !== undefined), `uploads/DoorColor/${res.data.doorColor.guid + '_' +res.data.doorColor.id}/thumbnail/`).then(thumbnail => {                        
                    uploadFiles(data.texture.filter(file => file.originFileObj !== undefined), `uploads/DoorColor/${res.data.doorColor.guid + '_' +res.data.doorColor.id}/texture/`).then(texture => {                        
                        const uploadData = [
                            {
                                fieldName: 'thumbnail',
                                type: 'single',
                                data: thumbnail
                            },
                            {
                                fieldName: 'texture',
                                type: 'single',
                                data: texture
                            }
                        ];
                        axios.post(`${API_SERVER_URL}/api/base/updateUploadData`, 
                            {
                                id: res.data.doorColor.id,
                                tableName: "door_color",
                                data: uploadData
                            }
                        )
                        .then(response => {
                            const {success} = response.data;
                            dispatch(uploadLoading(false));
                            if(success === true) {
                                dispatch(showMessage({
                                    message         : 'Successfully Inserted',
                                    autoHideDuration: 2000,
                                    anchorOrigin    : {
                                        vertical  : 'top',
                                        horizontal: 'right'
                                    },variant : 'success'
                                }));
                                Promise.all([
                                    dispatch({
                                        type: ADD_DOOR_COLOR
                                    })
                                ]).then(() => dispatch(getDoorColor()))
                            } else {
                                console.log(success);
                            }
                        })
                    })
                    .catch(err => {
                        console.log("failed to upload door color texture. ", err);
                        return;
                    })
                })
                .catch(err => {
                    console.log("failed to upload door color thumbnail. ", err);
                    return;
                })
            } else {
                formData.append("guid" , res.data.doorColor.guid);
                formData.append("id" , res.data.doorColor.id);   
                axios.post(`${API_SERVER_URL}/api/doorColor/upload`, formData)
                .then(response => {
                    const {success} = response.data;
                    dispatch(uploadLoading(false));
                    if(success === true) {
                        axios.post(`${API_SERVER_URL}/uploads/clear`)
                        .then(res => {
                            if(res.data.success === true ){
                                dispatch(showMessage({
                                    message         : 'Successfully Inserted',
                                    autoHideDuration: 2000,
                                    anchorOrigin    : {
                                        vertical  : 'top',
                                        horizontal: 'right'
                                    },variant : 'success'
                                }));
                                Promise.all([
                                    dispatch({
                                        type:ADD_DOOR_COLOR,
                                    })
                                ]).then(() => dispatch(getDoorColor()))
                            }
                        })
                    }
                })   
            } 
        })
        .catch(err => {
            dispatch(showMessage({
                message         : 'Failed to add DOOR COLOR data',
                autoHideDuration: 2000,
                anchorOrigin    : {
                    vertical  : 'top',
                    horizontal: 'right'
                },variant : 'error'
            }));

            console.log("err", err);
        });
    })
}

export const DELETE_DOOR_COLOR = '[DOOR_COLOR] DELETE DOOR COLOR';
export function deleteDoorColor(record) {
    return ((dispatch) => {
        var p1 = new Promise((resolve, reject) => {
            dispatch(uploadLoading(true));
            if(process.env.REACT_APP_MODE === "production" ) {
                const data = [];
                data.push(record.thumbnail);
                data.push(record.texture);
                deleteFiles(data).then(res => {
                    resolve("success");
                })
                .catch(err => {
                    console.log(err);
                    reject(err);
                })
            } else {
                resolve("success");
            }
        })
        p1.then(p1Value => {
            axios.post(`${API_SERVER_URL}/api/doorColor/remove/door_color`, {
                id: record.id
            })
            .then(response => {
                const {success} = response.data;
                dispatch(uploadLoading(false));
                if(success === true) {
                    dispatch(showMessage({
                        message         : 'Successfully Deleted',
                        autoHideDuration: 2000,
                        anchorOrigin    : {
                            vertical  : 'top',
                            horizontal: 'right'
                        },variant : 'success'
                    }));
                    Promise.all([
                        dispatch({
                            type:DELETE_DOOR_COLOR,
                        })
                    ]).then(() => dispatch(getDoorColor()))
                }
            }).catch(err => {
                dispatch(uploadLoading(false));
                dispatch(showMessage({
                    message         : 'Failed to delete DOOR COLOR data',
                    autoHideDuration: 2000,
                    anchorOrigin    : {
                        vertical  : 'top',
                        horizontal: 'right'
                    },variant : 'error'
                }));

                console.log("err", err);
            });    
        })
        .catch (err => {
            dispatch(uploadLoading(false));
            dispatch(showMessage({
                message: 'AWS S3 DELETE DOOR_COLOR ERROR',
                autoHideDuration: 4000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
                variant: 'error'
            }));
            return;
        })
    })
}

export const UPDATE_DOOR_COLOR = '[DOOR_COLOR] UPDATE DOOR COLOR';
export function updateDoorColor(data, id, guid) {
    var getArray = (data) => {
        var items = '';
        data.forEach(( fElement, index) => {
            if(index === 0)
                items += fElement;
            else
                items += ',' + fElement;
        });
        return items;
    }
    var updateData = {
        name            : data.name,
        door_style_id   : getArray(data.door_style_id),
        collection_id   : data.collection_id,
        thumbnail       : jsonArray([data.thumbnail[0]]),
        texture         : data.texture.length === 0 ?  "" : jsonArray([data.texture[0]]),
        color           : data.color,
        glossness          : data.glossness,
        id              : id,
        sku             : data.sku,
        color_family_id : getArray(data.color_family_id),
    }

    return ((dispatch) => {
            
        axios.post(`${API_SERVER_URL}/api/doorColor/update/door_color`, {
                ...updateData
            }).then(res => {
                dispatch(uploadLoading(true));
                if(process.env.REACT_APP_MODE === "production") {
                    putFiles(data.thumbnail.filter(file => file.originFileObj !== undefined), `uploads/DoorColor/${guid + '_' + id}/thumbnail/`).then(thumbnail => {                        
                        putFiles(data.texture.filter(file => file.originFileObj !== undefined), `uploads/DoorColor/${guid + '_' + id}/texture/`).then(texture => {                        
                            const uploadData = [
                                {
                                    fieldName: 'thumbnail',
                                    type: 'single',
                                    data: thumbnail
                                },
                                {
                                    fieldName: 'texture',
                                    type: 'single',
                                    data: texture
                                }
                            ];
        
                            axios.post(`${API_SERVER_URL}/api/base/updateUploadData`, 
                                {
                                    id: id,
                                    tableName: "door_color",
                                    data: uploadData
                                }
                            )
                            .then(response => {
                                const {success} = response.data;
                                dispatch(uploadLoading(false));
                                if(success === true) {
                                    dispatch(showMessage({
                                        message         : 'Successfully Updated',
                                        autoHideDuration: 2000,
                                        anchorOrigin    : {
                                            vertical  : 'top',
                                            horizontal: 'right'
                                        },variant : 'success'
                                    }));
                                    Promise.all([
                                        dispatch({
                                            type: UPDATE_DOOR_COLOR
                                        })
                                    ]).then(() => dispatch(getDoorColor()))
                                } else {
                                    console.log(success);
                                }
                            })
                        })
                        .catch(err => {
                            console.log("failed to put door color texture. ", err);
                            return;
                        })
                    })
                    .catch(err => {
                        console.log("failed to put door color thumbnail. ", err);
                        return;
                    })
                } else {
                    if(data.thumbnail[0].originFileObj !== undefined || (data.texture.length !== 0 &&  data.texture[0].originFileObj !== undefined)) {
                        const formData = new FormData();
                        formData.append("guid" , guid);
                        formData.append("id" , id);
                        if(data.thumbnail[0].originFileObj !== undefined) {
                            formData.append("thumbnail", data.thumbnail[0].originFileObj);
                        }
                        if(data.texture.length !== 0 &&  data.texture[0].originFileObj !== undefined) {
                            formData.append("texture", data.texture[0].originFileObj);
                        }
        
                        axios.post(`${API_SERVER_URL}/api/doorColor/upload`, formData)
                        .then(response => {
                            const {success} = response.data;
                            dispatch(uploadLoading(false));
                            if(success === true) {
                                axios.post(`${API_SERVER_URL}/uploads/clear`)
                                .then(res => {
                                    if(res.data.success === true ){
                                        dispatch(showMessage({
                                            message         : 'Successfully Updated',
                                            autoHideDuration: 2000,
                                            anchorOrigin    : {
                                                vertical  : 'top',
                                                horizontal: 'right'
                                            },variant : 'success'
                                        }));
                                        Promise.all([
                                            dispatch({
                                                type:UPDATE_DOOR_COLOR,
                                            })
                                        ]).then(() => dispatch(getDoorColor()))
                                    }
                                })
                            }
                        })
                    }
                    else {
                        dispatch(uploadLoading(false));
                        dispatch(showMessage({
                            message         : 'Successfully Updated',
                            autoHideDuration: 2000,
                            anchorOrigin    : {
                                vertical  : 'top',
                                horizontal: 'right'
                            },variant : 'success'
                        }));
                        Promise.all([
                            dispatch({
                                type:UPDATE_DOOR_COLOR,
                            })
                        ]).then(() => dispatch(getDoorColor()))
                    }
                }
            })
            .catch(err => {
                dispatch(showMessage({
                    message         : 'Failed to update DOOR COLOR data',
                    autoHideDuration: 2000,
                    anchorOrigin    : {
                        vertical  : 'top',
                        horizontal: 'right'
                    },variant : 'error'
                }));
    
                console.log("err", err);
            });
         
    })
}

export const SET_DOOR_COLOR_SORT_ORDER = '[DOOR_COLOR] SET SORT ORDER';
export function setSortOrder(data) {
    return ((dispatch) => {
        dispatch(uploadLoading(true));
        axios.post(`${API_SERVER_URL}/api/base/setSortOrder`, {
            id: data.id,
            sort_order: data.sort_order,
            table_name: 'door_color'
        })
        .then(response => {
            const {success} = response.data;
            if(success === true) {
                dispatch(uploadLoading(false));
                Promise.all([
                    dispatch({
                        type:SET_DOOR_COLOR_SORT_ORDER,
                    })  
                ]).then(() => dispatch(getDoorColor()))
            } else {
                dispatch(uploadLoading(false));
            }
        })
        .catch(err => {
            dispatch(uploadLoading(false));
            dispatch(showMessage({
                message         : 'Failed to set sort order',
                autoHideDuration: 2000,
                anchorOrigin    : {
                    vertical  : 'top',
                    horizontal: 'right'
                },variant : 'error'
            }));
            console.log("failed to set sort order in door color.", err);
        })
    })
}